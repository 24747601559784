import React from 'react'
import {
  IonContent,
  IonIcon,
  IonButton,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonButtons,
  IonAlert,
  IonFab,
  IonFabButton,
  IonText,
  IonSpinner,
} from '@ionic/react'
import { observer, inject } from 'mobx-react'
import { AppStore } from '../../../stores/AppStore'
import { close, trashOutline, checkmarkSharp, refreshCircle, refreshOutline } from 'ionicons/icons'
import { RootStore } from '../../../stores/RootStore'
import { match } from 'react-router'
import Content from './Content'
import BackIcon from 'src/shared/BackIcon'
import { BotSessionsStore } from '../../store/BotSessionsStore'

interface Props {
  botSessionsStore: BotSessionsStore
  appStore: AppStore
  rootStore: RootStore
  history: any
  match: match
}

@inject('botSessionsStore')
@inject('appStore')
@observer
export default class BotSessionEditPage extends React.Component<Props> {
  constructor(props) {
    super(props)
  }

  componentDidMount() {
    this.props.appStore.setCurrentRoute('/botsessionedit/' + this.props.match.params['id'])
    this.props.botSessionsStore.lazyLoadEditVM(this.props.match.params['id'])
  }

  componentWillUnmount() {
    this.props.botSessionsStore.lazyLoadEditVM('empty')
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<{}>, snapshot?: any): void {
    if (this.props.match.params['id'] !== prevProps.match.params['id']) {
      this.props.botSessionsStore.lazyLoadEditVM(this.props.match.params['id'])
    }
  }

  handleGoBack() {
    this.props.appStore.handleGoBack(this.props.history)
  }

  renderDeleteConfirm() {
    const { editVM } = this.props.botSessionsStore
    if (!editVM) return null
    return (
      <IonAlert
        isOpen={editVM.deleteConfirmShown}
        onDidDismiss={() => editVM.hideDeleteConfirm()}
        header={'Delete Recipe'}
        message={'Are you sure you wish to delete?'}
        buttons={[
          {
            text: 'No',
            role: 'cancel',
            cssClass: 'primary',
            handler: () => editVM.hideDeleteConfirm(),
          },
          {
            text: 'Yes',
            handler: () => editVM.delete(),
          },
        ]}
      />
    )
  }

  render() {
    const { editVM } = this.props.botSessionsStore
    if (!editVM) return null

    return (
      <>
        {this.props.appStore.showAppHeader && (
          <IonHeader>
            <IonToolbar />
          </IonHeader>
        )}

        <IonHeader id='BotSessionEditPage_Header'>
          <IonToolbar color='secondary'>
            <IonButtons slot='start'>
              <IonButton onClick={() => this.handleGoBack()}>
                <BackIcon />
              </IonButton>
            </IonButtons>

            <IonTitle>{editVM.pageTitle}</IonTitle>
            <IonButtons slot='end'>
              <IonButton onClick={() => editVM.startNewSession()}>
                <IonIcon icon={refreshOutline} />
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>

        <Content botSessionGuid={this.props.match.params['id']} />

        {this.renderDeleteConfirm()}
      </>
    )
  }
}
