export default {
  spacing: {
    small: 4,
    default: 12,
    medium: 14,
    padding: 16,
    large: 20,
    extraLarge: 32,
  },
  // separator: {
  //   lineThickness: 1,
  //   lineColor: '#E6E6E6',
  // },
  // imageSizes: {
  //   small: 32,
  //   medium: 48,
  //   large: 96,
  // },
  fontTypes: {
    default: {
      fontFamily: 'Lato-Regular, Tahoma, Geneva, Verdana, sans-serif',
      fontSizes: {
        small: 12,
        default: 14,
        medium: 16,
        large: 18,
        extraLarge: 24,
      },
      fontWeights: {
        lighter: 200,
        default: 400,
        bolder: 600,
      },
    },
    monospace: {
      fontFamily: 'monospace',
      fontSizes: {
        small: 12,
        default: 14,
        medium: 16,
        large: 18,
        extraLarge: 24,
      },
      fontWeights: {
        lighter: 200,
        default: 400,
        bolder: 600,
      },
    },
  },
  //   textStyles: {
  //     heading: {
  //       fontType: 'default',
  //       size: 'large',
  //       weight: 'bolder',
  //       color: 'default',
  //       isSubtle: false,
  //     },
  //   },
  //   textBlock: {
  //     headingLevel: 2,
  //   },
  //   fontSizes: {
  //     small: 12,
  //     default: 14,
  //     medium: 16,
  //     large: 18,
  //     extraLarge: 24,
  //   },
  //   fontWeights: {
  //     lighter: 200,
  //     default: 400,
  //     bolder: 600,
  //   },
  containerStyles: {
    default: {
      borderColor: '#CCCCCC',
      backgroundColor: '#ffffff',
      foregroundColors: {
        default: {
          default: '#000000',
          subtle: '#737373',
        },
      },
    },
    accent: {
      backgroundColor: 'var(--ion-color-medium)',
    },
    emphasis: {
      backgroundColor: 'rgba(var(--ion-color-light-rgb), 0.9)',
    },
  },
  actions: {
    maxActions: 5,
    spacing: 'Default',
    buttonSpacing: 12,
    showCard: {
      actionMode: 'Inline',
      inlineTopMargin: 16,
      style: 'Emphasis',
    },
    preExpandSingleShowCardAction: false,
    actionsOrientation: 'Horizontal',
    actionAlignment: 'Left',
    iconSize: 20,
    iconPlacement: 'leftOfTitle',
  },
  //   imageSet: {
  //     imageSize: 'Medium',
  //     maxImageHeight: 100,
  //   },
  //   factSet: {
  //     title: {
  //       size: 'Default',
  //       color: 'Default',
  //       isSubtle: false,
  //       weight: 'Bolder',
  //       wrap: true,
  //       maxWidth: 150,
  //     },
  //     value: {
  //       size: 'Default',
  //       color: 'Default',
  //       isSubtle: false,
  //       weight: 'Default',
  //       wrap: true,
  //     },
  //     spacing: 12,
  //   },
}
