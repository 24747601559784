import { observable, action, makeObservable, computed } from 'mobx'
import { RootStore } from 'src/stores/RootStore'
import { BotSessionsService } from '../../services/BotSessionsService'

export class BotSessionEditModalVM {
  private rootStore: RootStore
  private onHide: () => void
  private parentRecordGuid: string
  private parentRecordTable: string
  @observable private refreshCount: number = 0

  constructor({
    rootStore,
    onHide,
    parentRecordTable,
    parentRecordGuid,
  }: {
    rootStore?: RootStore
    onHide?: () => void
    parentRecordTable?: string
    parentRecordGuid?: string
  }) {
    makeObservable(this)
    this.rootStore = rootStore
    this.parentRecordTable = parentRecordTable
    this.parentRecordGuid = parentRecordGuid
    this.onHide = onHide
  }

  @observable public shown: boolean = false

  @action
  public setShown(val: boolean): void {
    this.shown = val
  }

  @action
  public hide() {
    this.shown = false
    this.onHide()
  }

  public goBack(): void {
    this.hide()
  }

  @action
  public refresh() {
    if (!this.parentRecordGuid) return
    const found = this.rootStore.botSessionsStore.currentBoardRecords.find(
      (e) => e.ParentRecordTable === this.parentRecordTable && e.ParentRecordGuid == this.parentRecordGuid
    )
    if (!found) return
    found.markAsDeleted()
    const svc = new BotSessionsService(this.rootStore)
    svc.save(found)
    this.refreshCount++
  }

  @computed
  public get key() {
    return `${this.parentRecordTable}-${this.parentRecordGuid}-${this.refreshCount}`
  }
}
