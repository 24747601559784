import { action, makeObservable, observable } from 'mobx'
import { RootStore } from '../../stores/RootStore'

export class ImagesGridVM {
  constructor(private rootStore: RootStore, private sortable: boolean) {
    makeObservable(this)
  }

  @observable public sortMode: boolean = true

  @action
  public startSort() {
    if (!this.sortable) return
    this.sortMode = true
    // setTimeout(() => this.endSort(), 4000)
  }

  @action
  public endSort() {
    if (!this.sortMode) return
    console.log('sort end')
    this.sortMode = false
  }
}
