import { Camera, CameraResultType, CameraSource } from '@capacitor/camera'
import { StorageFilesWriteService } from './StorageFilesWriteService'
import { RootStore } from '../../stores/RootStore'
import { StorageFilesService } from './StorageFilesService'
import { IStorageFileDTO } from '../dtos/IStorageFileDTO'

export class StorageFilesPhotoService {
  constructor(private rootStore: RootStore) {}

  public async takePhoto(availableOffline: boolean = false): Promise<IStorageFileDTO> {
    const hasCameraPermission = await Camera.checkPermissions()
    if (!hasCameraPermission.camera) {
      const permission = await Camera.requestPermissions()
      if (!permission.camera) return undefined
    }
    let image
    try {
      image = await Camera.getPhoto({
        quality: 90,
        allowEditing: false,
        resultType: CameraResultType.Base64,
        source: CameraSource.Prompt,
      })
      console.log('Camera image:', image)
    } catch (error) {
      console.error('Camera error:', error)
      return undefined
    }
    if (!image?.base64String) {
      console.error('No base64 string provided')
      return undefined
    }
    const writeSvc = new StorageFilesWriteService(this.rootStore)
    const storageFile = await writeSvc.saveBase64String(image.base64String, image.format, availableOffline)
    console.log('Storage file:', storageFile)
    const uploadSvc = new StorageFilesService(this.rootStore)
    // if (availableOffline) {
    //   const dto = await uploadSvc.uploadOfflineStorageFile(storageFile)
    // } else {
    const dto = await uploadSvc.uploadBase64(image.base64String, storageFile)
    // }
    return dto
  }
}
