export default {
  spacing: {
    small: 4,
    default: 12,
    medium: 14,
    padding: 16,
    large: 20,
    extraLarge: 32,
  },
  // separator: {
  //   lineThickness: 1,
  //   lineColor: '#292827',
  // },
  // imageSizes: {
  //   small: 72,
  //   medium: 164,
  //   large: 256,
  // },
  fontTypes: {
    default: {
      fontFamily: 'Lato-Regular, Tahoma, Geneva, Verdana, sans-serif',
      fontSizes: {
        small: 12,
        default: 14,
        medium: 16,
        large: 18,
        extraLarge: 24,
      },
      fontWeights: {
        lighter: 200,
        default: 400,
        bolder: 600,
      },
    },
    monospace: {
      fontFamily: 'monospace',
      fontSizes: {
        small: 12,
        default: 14,
        medium: 16,
        large: 18,
        extraLarge: 24,
      },
      fontWeights: {
        lighter: 200,
        default: 400,
        bolder: 600,
      },
    },
  },
  textBlock: {
    headingLevel: 2,
  },
  containerStyles: {
    default: {
      backgroundColor: '#1b1a19',
      foregroundColors: {
        default: {
          default: '#ffffff',
          subtle: '#a19f9d',
        },
      },
    },
    accent: {
      backgroundColor: 'var(--ion-color-medium)',
      foregroundColors: {
        default: {
          default: 'var(--ion-text-color)',
          subtle: '#a19f9d',
        },
      },
    },
    emphasis: {
      backgroundColor: 'rgba(var(--ion-color-light-rgb), 0.9)',
      foregroundColors: {
        default: {
          default: 'var(--ion-text-color)',
        },
      },
    },
  },
  actions: {
    maxActions: 5,
    spacing: 'Default',
    buttonSpacing: 12,
    showCard: {
      actionMode: 'Inline',
      inlineTopMargin: 16,
      style: 'Emphasis',
    },
    preExpandSingleShowCardAction: false,
    actionsOrientation: 'Horizontal',
    actionAlignment: 'Right',
    iconSize: 20,
    iconPlacement: 'leftOfTitle',
  },
  adaptiveCard: {
    allowCustomStyle: true,
  },
  imageSet: {
    imageSize: 'Medium',
    maxImageHeight: 100,
  },
  factSet: {
    title: {
      color: 'Default',
      size: 'Default',
      isSubtle: false,
      weight: 'Bolder',
      wrap: true,
      maxWidth: 150,
    },
    value: {
      color: 'Default',
      size: 'Default',
      isSubtle: false,
      weight: 'Default',
      wrap: true,
    },
    spacing: 8,
  },
}
