import { computed } from 'mobx'
import { RootStore } from '../../stores/RootStore'

export class AppUpdateAvailableCardVM {
  constructor(private rootStore: RootStore) {}

  @computed
  public get hasUpdateAvailable() {
    return this.rootStore.appStore.hasAvailableAppUpdate
  }

  public updateNow() {
    if (this.rootStore.appStore.isAndroid) {
      window.open('https://play.google.com/store/apps/details?id=com.groceryboard.io')
    }
    if (this.rootStore.appStore.isIos) {
      window.open('https://apps.apple.com/us/app/grocery-board/id1471128617')
    }
  }
}
