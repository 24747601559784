import { observable, action, makeObservable } from 'mobx'
import { IBotSessionDTO } from '../dtos/IBotSessionDTO'
import { serialize, deserialize, serializable, list, date } from 'serializr'
import { IAggregrate } from '../../shared/IAggregate'
import { Interaction } from './Interaction'
import { persist } from 'mobx-persist'
import generateUUID from '../../utils/UUID'

export class BotSession implements IBotSessionDTO, IAggregrate {
  public static create(boardId: number, bot: string, title: string) {
    const session = new BotSession()
    session.BotSessionGuid = generateUUID()
    session.BoardId = boardId
    session.Bot = bot
    session.Title = title
    session.CreatedDateTime = new Date()
    session.LastUpdatedDateTime = new Date()
    BotSession.setDefaultSettings(session)
    return session
  }

  private static setDefaultSettings(session: BotSession) {
    if (session.Bot === 'recipe-from-photo-v1') {
      session.CanCaptureAudio = false
      session.CanSubmit = false
      session.CanUploadPhoto = true
      session.addUploadImageInteraction()
    }
    if (session.Bot === 'capture-list-items-voice-v1') {
      session.CanCaptureAudio = true
      session.CanSubmit = false
      session.CanUploadPhoto = false
    }
    if (session.Bot === 'generate-recipe-image-v1') {
      session.CanCaptureAudio = false
      session.CanSubmit = false
      session.CanUploadPhoto = false
      session.autoSave = true
      session.addPendingImageInteraction()
    }
    if (session.Bot === 'generate-item-image-v1') {
      session.CanCaptureAudio = false
      session.CanSubmit = false
      session.CanUploadPhoto = false
      session.autoSave = true
      session.addPendingImageInteraction()
    }
  }

  constructor() {
    makeObservable(this)
  }

  @serializable @observable public BotSessionGuid: string = ''
  @serializable @observable public BoardId: number = 0
  @serializable @observable public Bot: string = ''
  @serializable @observable public ParentRecordTable: string = ''
  @serializable @observable public ParentRecordGuid: string = ''
  @serializable @observable public Title: string = ''
  @serializable @observable public Description: string = ''
  @serializable @observable public DescriptionLocked: boolean = false
  @persist('list', Interaction) @observable public Interactions: Interaction[] = []
  @serializable @observable public CanCaptureAudio: boolean = false
  @serializable @observable public CanSubmit: boolean = false
  @serializable @observable public CanUploadPhoto: boolean = false
  @serializable @observable public IsDeleted: boolean = false
  @serializable(date()) @observable public CreatedDateTime: Date
  @serializable(date()) @observable public LastUpdatedDateTime: Date
  @serializable @observable public autoSave: boolean = false
  public isOnServer: boolean = false

  @action
  public setAnswer(interactionGuid: string, adaptiveCardAnswer: any) {
    const int = this.getInteraction(interactionGuid)
    if (!int) return
    int.setAnswer(adaptiveCardAnswer)
    this.deleteLaterInteractions(int.Rank)
  }

  @action
  public setSaveRecipeRequested(interactionGuid: string) {
    const int = this.getInteraction(interactionGuid)
    if (!int) return
    int.setDetails('SaveRecipeRequested', true)
    int.markAsProcessing()
  }

  @action
  public setGenerateRecipeFromPhotoRequsted(interactionGuid: string) {
    const int = this.getInteraction(interactionGuid)
    if (!int) return
    int.setDetails('GenerateRecipeFromPhotoRequested', true)
    int.markAsProcessing()
  }

  @action
  public setSelectedOption(interactionGuid: string, option: string) {
    const int = this.getInteraction(interactionGuid)
    if (!int) return
    int.setDetails('SelectedOption', option)
    int.setDetails('FinalAnswer', '')
  }

  @action
  public toggleSelectedIngredient(interactionGuid: string, ingredient: string) {
    const int = this.getInteraction(interactionGuid)
    if (!int) return
    const current = int.details['SelectedIngredients'] || []
    current.includes(ingredient) ? current.splice(current.indexOf(ingredient), 1) : current.push(ingredient)
    int.setDetails('SelectedIngredients', current)
  }

  @action
  public markAsRecipesRequested(interactionGuid: string) {
    const int = this.getInteraction(interactionGuid)
    if (!int) return
    int.setDetails('RecipesRequested', true)
    int.markAsProcessing()
  }

  @action
  public setRecipeToGenerate(interactionGuid: string, recipeName: string) {
    const int = this.getInteraction(interactionGuid)
    if (!int) return
    int.setDetails('RecipeToGenerate', recipeName)
    int.markAsProcessing()
  }

  @action
  public markAsAnswered(interactionGuid: string, finalAnswer: string) {
    const int = this.getInteraction(interactionGuid)
    if (!int) return
    int.setDetails('Answered', true)
    int.setDetails('FinalAnswer', finalAnswer)
    int.markAsProcessing()
  }

  @action
  public setGetRecipeFromUrlRequested(interactionGuid: string, url: string) {
    const int = this.getInteraction(interactionGuid)
    if (!int) return
    int.setDetails('GetRecipeFromUrlRequested', true)
    int.setDetails('url', url)
    int.markAsProcessing()
  }

  @action
  public addStorageFileInteraction(storageFileGuid: string) {
    const interaction = Interaction.createStorageFileInteraction(storageFileGuid, this.Interactions.length + 1)
    this.Interactions.push(interaction)
    return interaction
  }

  @action
  public addPendingInteraction() {
    const interaction = Interaction.createPendingInteraction(this.Interactions.length + 1)
    this.Interactions.push(interaction)
    return interaction
  }

  @action
  public addPendingImageInteraction() {
    const interaction = Interaction.createPendingImageInteraction(this.Interactions.length + 1)
    this.Interactions.push(interaction)
    return interaction
  }

  @action
  public addUploadImageInteraction() {
    const interaction = Interaction.createUploadImageInteraction(this.Interactions.length + 1)
    this.Interactions.push(interaction)
    return interaction
  }

  private deleteLaterInteractions(rank: number) {
    this.Interactions.filter((e) => e.Rank > rank).forEach((e) => e.markAsDeleted())
  }

  public getInteraction(interactionGuid: string): Interaction | undefined {
    return this.Interactions.find((i) => i.InteractionGuid === interactionGuid)
  }

  @action
  public setDescription(val: string) {
    this.Description = val
  }

  public markIsOnServer(): void {
    this.isOnServer = true
  }

  public markIsNotOnServer(): void {
    this.isOnServer = false
  }

  public clone(): BotSession {
    return deserialize(BotSession, serialize(this))
  }

  public toDTO(): IBotSessionDTO {
    return serialize(this)
  }

  @action
  public setParentRecord(parentRecordTable: string, parentRecordGuid: string) {
    this.ParentRecordTable = parentRecordTable
    this.ParentRecordGuid = parentRecordGuid
  }

  @action
  public markAsDeleted() {
    this.IsDeleted = true
  }
}
