import React, { useEffect, useLayoutEffect, useRef } from 'react'
import { IonCard } from '@ionic/react'
import AdaptiveCard from '../../../adaptive-cards/component/AdaptiveCard'
import { BotSessionInteractionCardVM } from '../../view-models/edit/BotSessionInteractionCardVM'
import { observer } from 'mobx-react'

interface Props {
  vm: BotSessionInteractionCardVM
}

const BotSessionInteractionCard: React.FC<Props> = ({ vm }) => {
  const cardRef = useRef<HTMLIonCardElement>(null)
  useLayoutEffect(() => {
    vm.setIonCard(cardRef.current)
  }, [])
  return (
    <IonCard key={vm.interactionGuid} ref={cardRef}>
      <AdaptiveCard
        // key={vm.key}
        cardKey={vm.key}
        card={vm.adaptiveCardTemplate}
        data={vm.adaptiveCardData}
        onSubmit={(card, actionData) => vm.handleSubmit(card, actionData)}
      />
    </IonCard>
  )
}

export default observer(BotSessionInteractionCard)
