interface IParseIngredientResponse {
  RecipeItemGuid: string
  Description: string
  itemDetails: IParseIngredientResponse.IItemDetail
  matches: IParseIngredientResponse.IMatchInfo[]
}

namespace IParseIngredientResponse {
  export interface IMatchInfo {
    itemName: string
    itemId: string
    confidence?: number
  }

  export interface IItemDetail {
    itemName: string
    categoryId: string
    categoryName: string
  }
}

export class ParseIngredientResponse {
  private dto: IParseIngredientResponse

  constructor(dto: IParseIngredientResponse) {
    this.dto = dto
  }

  public get highConfidenceMatch(): IParseIngredientResponse.IMatchInfo | undefined {
    return this.dto.matches
      .filter((match) => match.confidence === 1)
      .sort((a, b) => (b.confidence || 0) - (a.confidence || 0))[0]
  }

  public get suggestedNewItem(): IParseIngredientResponse.IItemDetail {
    return this.dto.itemDetails
  }

  public get RecipeItemGuid(): string {
    return this.dto.RecipeItemGuid
  }

  public get Description(): string {
    return this.dto.Description
  }

  public get itemDetails(): IParseIngredientResponse.IItemDetail {
    return this.dto.itemDetails
  }

  public get matches(): IParseIngredientResponse.IMatchInfo[] {
    return this.dto.matches.filter(e => e.confidence > 0.5)
  }
}
