import React, { useEffect } from 'react'
import { inject, observer, useLocalObservable } from 'mobx-react'
import { ImagesViewerVM } from '../view-models/ImagesViewerVM'
import { RootStore } from '../../stores/RootStore'
import Lightbox from 'yet-another-react-lightbox'
import Zoom from '../../../node_modules/yet-another-react-lightbox/dist/plugins/zoom'
import 'yet-another-react-lightbox/dist/styles.css'
import { StorageFile } from '../aggregate/StorageFile'

interface Props {
  rootStore?: RootStore
  isOpen: boolean
  onClose: () => void
  storageFiles: StorageFile[]
  initialIndex?: number
}

const ImagesViewer: React.FC<Props> = ({ initialIndex, rootStore, isOpen, onClose, storageFiles }) => {
  const vm = useLocalObservable(() => new ImagesViewerVM({ rootStore, isOpen, onClose, storageFiles }))
  // const zoomRef = React.useRef(null)
  useEffect(() => {
    vm.setIndex(initialIndex)
    vm.setOpen(isOpen)
  }, [initialIndex, isOpen])

  return (
    <Lightbox
      index={vm.initialIndex}
      open={isOpen}
      plugins={[Zoom]}
      // zoom={{ ref: zoomRef }}
      close={() => vm.handleClose()}
      slides={[...vm.urls.map((url) => ({ src: url }))]}
    />
  )
}

export default inject('rootStore')(observer(ImagesViewer))
