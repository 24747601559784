import { Browser } from '@capacitor/browser'
import { RootStore } from '../../../stores/RootStore'

export class PdfOpenService {
  constructor(private rootStore: RootStore) {}

  public async openPdf(url: string) {
    const bearer = 'Bearer ' + this.rootStore.appStore.token
    const blobData = await fetch(url, {
      method: 'GET',
      headers: {
        Authorization: bearer,
      },
    })
    const data = await blobData.arrayBuffer()
    var base64Str = Buffer.from(data).toString('base64')

    var binaryString = window.atob(base64Str)
    var binaryLen = binaryString.length
    var bytes = new Uint8Array(binaryLen)
    for (var i = 0; i < binaryLen; i++) {
      var ascii = binaryString.charCodeAt(i)
      bytes[i] = ascii
    }
    var arrBuffer = bytes

    var newBlob = new Blob([arrBuffer], { type: 'application/pdf' })
    const href = window.URL.createObjectURL(newBlob)

    var link = document.createElement('a')
    document.body.appendChild(link)
    link.href = href
    link.download = 'Grocery_Board_Shopping_List.pdf'
    link.click()
    window.URL.revokeObjectURL(href)
    link.remove()
  }
}
