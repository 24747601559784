import { CategoryNewVM } from '../../../items-select/view-models/CategoryNewVM'
import { RootStore } from '../../../stores/RootStore'
import { computed, observable, action, makeObservable, reaction, runInAction } from 'mobx'
import { Item } from '../../aggregate/Item'
import { ItemsService } from '../../services/ItemsService'
import { ListItem } from '../../../list-items/aggregate/ListItem'
import { ListItemsService } from '../../../list-items/services/ListItemsService'
import { Category } from '../../../categories/aggregate/Category'
import { FutureItemVM } from '../../../list-items/view-models/FutureItemVM'
import { StorageFile } from '../../../storage-files/aggregate/StorageFile'
import { Camera, CameraResultType, CameraSource } from '@capacitor/camera'
import { StorageFilesService } from '../../../storage-files/services/StorageFilesService'
import { StorageFilesWriteService } from '../../../storage-files/services/StorageFilesWriteService'
import { arrayMoveImmutable } from 'array-move'
import generateUUID from '../../../utils/UUID'
import { StorageFilesPhotoService } from '../../../storage-files/services/StorageFilesPhotoService'
import { IStorageFileDTO } from '../../../storage-files/dtos/IStorageFileDTO'
import { deserialize } from 'serializr'
import { StorageFilesDownloadService } from '../../../storage-files/services/StorageFilesDownloadService'

export class ItemEditVM {
  private rootStore: RootStore
  public isNewItem: boolean = false
  private contentRef: HTMLIonContentElement
  @observable private item: Item = undefined
  private reactions: Array<() => void> = []

  constructor(rootStore: RootStore, item: Item, isNew: boolean = false) {
    makeObservable(this)
    this.rootStore = rootStore
    this.item = item
    this.isNewItem = isNew
    this.categoryNewVM = new CategoryNewVM(this.rootStore, this)
    this.loadStorageFiles()
  }

  @observable public addCategoryEvent: any = null
  @observable public categoryMenuShown: boolean = false
  @observable public categoryNewVM: CategoryNewVM = null
  @observable public deleteConfirmShown: boolean = false
  @observable public currentTabIndex: string = '0'
  @observable public imagesViewerShown: boolean = false
  @observable public imagesViewerIndex: number = 0
  @observable public generateImageModalShown: boolean = false
  @observable public storageFiles: StorageFile[] = []

  @computed
  public get itemName(): string {
    return this.item.Name
  }

  public setContentRef(e: HTMLIonContentElement) {
    this.contentRef = e
    this.rootStore.appStore.applyScrollStyles(e)
  }

  @computed
  public get listItem(): ListItem {
    return this.listItemsStore.getUnClearedByItemGuid(this.itemGuid)
  }

  @computed
  public get iconName(): string {
    if (this.isGotten) return 'checkmark'
    if (this.hasQuantity && !this.isGotten) return 'cart'
    return 'add'
  }

  @computed
  public get categoryColor(): string {
    if (!this.rootStore.categoriesStore) return ''
    const foundCat = this.rootStore.categoriesStore.get(this.categoryGuid)
    if (!foundCat) return ''
    return foundCat.Color
  }

  @computed
  public get name(): string {
    if (!this.item) return ''
    return this.item.Name
  }

  @action
  public setName(val: string) {
    this.item.setName(val)
  }

  @computed
  public get listItemNotes() {
    if (!this.listItem) return ''
    return this.listItem.Notes
  }

  @computed
  public get itemGuid(): string {
    return this.item.ItemGuid
  }

  @computed
  public get notes(): string {
    if (!this.item.Notes) return ''
    return this.item.Notes
  }

  @action
  public toggle() {
    if (!this.hasQuantity) {
      this.increaseQuantity()
      return
    }
    if (this.hasQuantity && !this.isGotten) {
      this.listItem.toggleGotten()
      this.saveListItem()
      return
    }
    if (this.hasQuantity && this.isGotten) {
      const listItem = this.listItem.clone()
      listItem.markAsCleared()
      this.saveListItem(listItem)
      return
    }
    this.deleteListItem()
  }

  @action
  public increaseQuantity() {
    if (!this.listItem) {
      const listItem = ListItem.create(this.rootStore.boardsStore.currentBoardId, this.itemGuid)
      listItem.increaseQuantity()
      this.saveListItem(listItem)
      return
    }
    this.listItem.increaseQuantity()
    this.saveListItem()
  }

  @action
  public decreaseQuantity() {
    this.listItem.decreaseQuantity()
    this.saveListItem()
  }

  @action
  private saveListItem(newListItem: ListItem = undefined) {
    const svc = new ListItemsService(this.rootStore)
    if (newListItem) {
      svc.save(newListItem.toDTO())
      return
    }
    svc.save(this.listItem.toDTO())
  }

  @action
  private deleteListItem() {
    const svc = new ListItemsService(this.rootStore)
    svc.delete(this.listItem.toDTO())
  }

  @computed
  public get isGotten(): boolean {
    if (!this.listItem) return false
    return this.listItem.isGotten
  }

  @computed
  public get quantity(): number {
    if (!this.listItem) return 0
    return this.listItem.Quantity
  }

  @computed
  public get hasQuantity(): boolean {
    return this.quantity !== 0
  }

  @action
  public setNotes(val: string) {
    this.item.setNotes(val)
  }

  @computed
  public get shoppingListInfo(): string {
    if (this.hasQuantity && !this.isGotten) return 'On Shopping List'
    if (this.isGotten) return 'Item Gotten'
    return 'Add to Shopping List'
  }

  @action
  public setListItemNotes(val: string) {
    this.listItem.setNotes(val)
  }

  @computed
  public get categoryGuid(): string {
    return this.item.CategoryGuid
  }

  @action
  public setCategory(val: string) {
    if (val === 'new') {
      this.categoryNewVM = new CategoryNewVM(this.rootStore, this)
      this.categoryNewVM.toggleShown()
      this.item.setCategory(null)
      return
    }
    this.item.setCategory(val)
  }

  @computed
  public get categories(): Array<Category> {
    if (!this.rootStore.categoriesStore) return []
    const cats = this.rootStore.categoriesStore.currentBoardRecords
      .sort((a, b) => (a.Name.toLowerCase() < b.Name.toLowerCase() ? -1 : 0))
      .slice()
    cats.push({ CategoryGuid: 'new', Name: 'Add New...' } as Category)
    return cats
  }

  @computed
  public get categoryName(): string {
    const cat = this.rootStore.categoriesStore.currentBoardRecords.find((e) => e.CategoryGuid === this.categoryGuid)
    if (!cat) return ''
    if (cat.Name === 'Uncategorized') return ''
    return cat.Name
  }

  @action
  public back() {
    this.rootStore.appStore.handleGoBack()
  }

  @computed
  public get itemsStore() {
    return this.rootStore.itemsStore
  }

  @computed
  public get listItemsStore() {
    return this.rootStore.listItemsStore
  }

  public getItemQuantity(itemGuid: string) {
    const foundListItem = this.listItemsStore.getByItemGuid(itemGuid)
    if (foundListItem) return foundListItem.Quantity
    return 0
  }

  public showCategoryNewModal() {
    this.categoryNewVM.toggleShown()
  }

  @action
  public async save(silent: boolean = false) {
    const itemsSvc = new ItemsService(this.rootStore)
    const itemDTO = this.item.toDTO()
    itemsSvc.saveItem(itemDTO)
    if (silent) return
    if (this.listItem) this.saveListItem()
    this.rootStore.appStore.handleGoBack()
  }

  @computed
  public get pageTitle(): string {
    return this.isNewItem ? 'Add Recipe' : this.item.Name
  }

  @action
  public showDeleteConfirm() {
    this.deleteConfirmShown = true
  }

  @action
  public hideDeleteConfirm() {
    this.deleteConfirmShown = false
  }

  @action
  public setCurrentTab(val: string) {
    if (this.currentTabIndex === val) return
    this.currentTabIndex = val
    this.contentRef.scrollToTop()
  }

  @action
  public async delete(shoppingListOnly: boolean) {
    if (shoppingListOnly) {
      const listItemsSvc = new ListItemsService(this.rootStore)
      await listItemsSvc.delete(this.listItem.toDTO())
      this.rootStore.appStore.handleGoBack()
      return
    }
    const svc = new ItemsService(this.rootStore)
    await svc.deleteItem(this.item.toDTO())
    this.rootStore.appStore.handleGoBack()
  }

  @computed
  public get futureItems(): FutureItemVM[] {
    const futureItems = []
    this.rootStore.mealsStore.currentBoardRecords
      .filter((e) => e.isInFuture)
      .forEach((meal) => {
        meal.MealRecipes.forEach((rcp) => {
          const foundRecipe = this.rootStore.recipesStore.get(rcp.RecipeGuid)
          if (foundRecipe) {
            const foundItems = foundRecipe.getRecipeItemsForItem(this.itemGuid)
            foundItems.forEach((e) => {
              futureItems.push(new FutureItemVM(this.rootStore, meal, foundRecipe, e, this.item))
            })
          }
        })
        meal.MealItems.filter((e) => e.ItemGuid === this.item.ItemGuid).forEach((e) => {
          const foundItem = this.rootStore.itemsStore.get(e.ItemGuid)
          if (!foundItem) return
          futureItems.push(new FutureItemVM(this.rootStore, meal, undefined, undefined, foundItem))
        })
      })
    return futureItems
  }

  @computed
  public get isValid(): boolean {
    if (this.name === '') return false
    return true
  }

  public async disableAutoComplete(e: HTMLIonInputElement) {
    if (!e) return
    const txt = await e.getInputElement()
    txt.setAttribute('autocomplete', 'something-new' + Math.random().toString() + generateUUID())
  }

  @action
  public openImagesViewer(idx: number): void {
    this.imagesViewerIndex = idx
    setTimeout(() => runInAction(() => (this.imagesViewerShown = true)), 200)
  }

  @action
  public closeImagesViewer(): void {
    this.imagesViewerShown = false
  }

  @action
  public openGenerateImage(): void {
    this.generateImageModalShown = true
  }

  @computed
  public get itemDescription(): string {
    let desc = this.item.Name
    if (this.notes) desc += ': ' + this.notes
    if (this.categoryName) desc += ': ' + this.categoryName
    return desc
  }

  @action
  public closeGenerateImageModal(): void {
    this.generateImageModalShown = false
  }

  public async takePhoto() {
    const svc = new StorageFilesPhotoService(this.rootStore)
    const dto = await svc.takePhoto(true)
    if (!dto) return
    this.item.addAttachment(dto.StorageFileGuid)
    this.loadStorageFiles()
    this.save(true)
  }

  @action
  public async addAttachment(dto: IStorageFileDTO) {
    this.generateImageModalShown = false
    const sf = deserialize(StorageFile, dto)
    sf.setAvailableOffline(true)
    const svc = new StorageFilesDownloadService(this.rootStore)
    await svc.download(sf)
    sf.markAsDownloaded()
    const sfSvc = new StorageFilesService(this.rootStore)
    sfSvc.save(sf)
    this.item.addAttachment(sf.StorageFileGuid)
    this.loadStorageFiles()
    this.save(true)
  }

  @action
  public sortAttachments(oldIdx: number, newIdx: number): void {
    const newRows = arrayMoveImmutable(
      this.item.Attachments.sort((a, b) => (a.Rank < b.Rank ? -1 : 0)).slice(),
      oldIdx,
      newIdx
    )
    newRows.forEach((e, idx) => {
      const att = this.item.getAttachment(e.AttachmentGuid)
      att.setRank(idx + 1)
    })
    this.loadStorageFiles()
  }

  @action
  private loadStorageFiles() {
    let atts = this.item.Attachments.slice()
      .sort((a, b) => (a.Rank < b.Rank ? -1 : 0))
      .map((e) => this.rootStore.storageFilesStore.get(e.StorageFileGuid))
      .filter((e) => e)
    this.storageFiles = atts
  }

  @computed
  public get hasItemImagesFeatureEnabled() {
    return this.rootStore.featuresStore.hasItemImagesFeature
  }

  @computed
  public get hasAIItemImagesFeatureEnabled() {
    return this.rootStore.featuresStore.hasAIItemImageFeature
  }
}
