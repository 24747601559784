import { observable, action, computed, makeObservable } from 'mobx'
import { RootStore } from 'src/stores/RootStore'
import agent from '../../Agent'

export default class ResetPasswordForm {
  private rootStore: RootStore

  constructor(rootStore: RootStore, token: string = '') {
    makeObservable(this)
    this.rootStore = rootStore
    this.token = token
  }

  @observable public token: string = ''
  @observable public password: string = ''
  @observable public passwordConfirm: string = ''
  @observable public errorMessages: Array<string> = []

  @computed 
  public get isValid() {
    if (this.password === '') return false
    if (this.passwordConfirm === '') return false
    if (this.password !== this.passwordConfirm) return false
    return true
  }

  @action 
  public async save(): Promise<boolean> {
    this.errorMessages = []
    const form = {
      Token: this.token,
      Password: this.password
    }
    return await this.saveOnServer(form)
  }

  async saveOnServer(form) {
    try {
      const results: Array<string> = await agent.Users.resetPassword(form)
      if (results[0] === 'Success' && results.length === 1) {
        return true
      }
      else {
        results.forEach(e => {
          this.errorMessages.push(e)
        })
      }
    }
    catch (e) {
      console.error(e)
    }
    return false
  }

}
