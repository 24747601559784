import { observable, action, makeObservable, computed } from 'mobx'
import { IStore } from '@elexient/elexiapp.bits.shared'
import { RootStore } from '../../stores/RootStore'
import { IFeaturesBag } from '../interfaces/IFeaturesBag'
import { makePersistable } from 'mobx-persist-store'

export class FeaturesStore implements IStore {
  private rootStore: RootStore

  constructor(rootStore) {
    makeObservable(this)
    makePersistable(this, { name: 'FeaturesStore', properties: ['features'] }).then((st) => {
      let isHydrated = false
      if (process.env.NODE_ENV === 'test') isHydrated = true
      if (st && st.isHydrated) isHydrated = true
    })
    this.rootStore = rootStore
  }

  @observable public features: IFeaturesBag = null

  @action
  public setFeatures(featuresBag: IFeaturesBag) {
    this.features = featuresBag
  }

  @computed
  public get hasItemImagesFeature(): boolean {
    return Boolean(this.features?.ItemImages)
  }

  @computed
  public get hasAIItemImageFeature(): boolean {
    return Boolean(this.features?.AIItemImages)
  }

  @computed
  public get hasVoiceModeFeature(): boolean {
    return Boolean(this.features?.VoiceMode)
  }

  @computed
  public get hasCookbookGridFeature(): boolean {
    return Boolean(this.features?.CookbookGrid)
  }

  @computed
  public get hasRecipeImagesFeature(): boolean {
    return Boolean(this.features?.RecipeImages)
  }

  @computed
  public get hasAIRecipeImagesFeature(): boolean {
    return Boolean(this.features?.AIRecipeImages)
  }

  @computed
  public get hasRecipeBotsScreenFeature(): boolean {
    return Boolean(this.features?.RecipeBotsScreen)
  }

  public clearData() {}
}
