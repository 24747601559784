import agent from '../../AgentV2'
import { RootStore } from '../../stores/RootStore'
import { IBotSessionDTO } from '../dtos/IBotSessionDTO'

export class BotSessionsService {
  private rootStore: RootStore

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
  }

  public async save(dto: IBotSessionDTO) {
    const savedDto = await agent.BotSessions.save(dto)
    this.rootStore.botSessionsStore.updateFromServer(savedDto)
  }

  public async delete(dto: IBotSessionDTO) {
    dto.IsDeleted = true
    const savedDto = await agent.BotSessions.delete(dto)
    this.rootStore.botSessionsStore.updateFromServer(savedDto)
  }
}
