import React from 'react'
import { observer } from 'mobx-react'
import { BotSessionRowVM } from '../../view-models/list/BotSessionRowVM'
import { IonItem, IonCard, IonCardContent } from '@ionic/react'

interface Props {
  row: BotSessionRowVM
}

const BotSessionRow: React.FC<Props> = ({ row }) => {
  return (
    <IonCard
      id='BotSessionRow'
      onClick={() => row.openBotSession()}
    >
      <IonCardContent>{row.title}</IonCardContent>
    </IonCard>
  )
}

export default observer(BotSessionRow)
