import agent from '../../Agent'
import { RootStore } from '../../stores/RootStore'
import { IListItemDTO } from '../dtos/IListItemDTO'
import { ActionTypeEnum } from '../../actions/enum/ActionTypeEnum'

export class ListItemsService {
  private rootStore: RootStore

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
  }

  public save(dto: IListItemDTO) {
    const itemDTO = this.rootStore.itemsStore.get(dto.ItemGuid)
    if (itemDTO) {
      const categoryDTO = this.rootStore.categoriesStore.get(itemDTO.CategoryGuid)
      dto.Item = itemDTO as any
      dto.Item.Category = categoryDTO
    } else {
      console.log('Item not found', dto)
      return
    }
    this.rootStore.listItemsStore.updateFromServer(dto)
    this.rootStore.actionsStore.addAction(ActionTypeEnum.LISTITEM_SAVE, dto)
  }

  public async saveOnServer(dto: IListItemDTO) {
    const savedDTO = await agent.ListItems.save(dto)
    if (savedDTO) this.rootStore.listItemsStore.updateFromServer(savedDTO)
  }

  public delete(dto: IListItemDTO) {
    dto.IsDeleted = true
    this.save(dto)
  }

  public clearCompleted() {
    this.rootStore.listItemsStore.currentBoardRecords
      .filter((e) => e.isGotten)
      .forEach((e) => {
        e.markAsCleared()
        this.save(e.toDTO())
      })
  }
}
