import { RootStore } from 'src/stores/RootStore'
import { action, computed } from 'mobx'
import { BotSessionsStore } from '../../store/BotSessionsStore'
import { BotSessionRowVM } from './BotSessionRowVM'

export class BotSessionsListVM {
  private rootStore: RootStore

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
  }

  public get botOptions() {
    return [
      {
        value: 'recipe-from-url-v1',
        title: 'Import',
        subTitle: "Paste in the url and we'll get just the good parts.",
        imageUrl:
          'https://plus.unsplash.com/premium_photo-1683865776032-07bf70b0add1?q=80&w=1932&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
        xsWidth: 6,
      },
      {
        value: 'recipe-wizard-v1',
        title: 'Create',
        subTitle: 'Answer some questions to get your recipe magic started.',
        imageUrl:
          'https://images.unsplash.com/photo-1633613286848-e6f43bbafb8d?q=80&w=1770&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
        xsWidth: 6,
      },
      {
        value: 'recipe-from-photo-v1',
        title: 'Upload',
        subTitle:
          "Upload pictures of a dish or a recipe card and we'll spill the beans on the ingredients and directions.",
        imageUrl:
          'https://images.unsplash.com/photo-1551183053-bf91a1d81141?q=80&w=1864&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
        xsWidth: 6,
      },
      {
        value: 'recipe-from-pantry-v1',
        title: 'Pantry',
        subTitle: "Pick what you've got in your pantry, and together we'll figure something out.",
        imageUrl:
          'https://images.unsplash.com/photo-1484480974693-6ca0a78fb36b?q=80&w=1772&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
        xsWidth: 6,
      },
      {
        value: 'blank-v1',
        title: 'Start from scratch',
        subTitle: "Start with a blank recipe. We'll help you match your ingredients.",
        imageUrl: 'https://groceryboard.io/wp-content/uploads/2024/05/blank-recipe-1.png',
        xsWidth: 12,
      },
    ]
  }

  @action
  public startSession(botId: string) {
    let url = '/botsessionnew/' + botId
    if (botId === 'blank-v1') url = '/recipeedit/new/?name='
    setTimeout(() => this.rootStore.appStore.navigateTo(url), 400)
  }

  @computed
  private get botSessionsStore(): BotSessionsStore {
    return this.rootStore.botSessionsStore
  }

  @computed
  public get rows(): BotSessionRowVM[] {
    return this.botSessionsStore.currentBoardRecords
      .filter(
        (e) =>
          e.BoardId !== 0 &&
          e.Bot !== 'parse-ingredient-v1' &&
          e.Bot !== 'generate-recipe-image-v1' &&
          e.Bot !== 'generate-item-image-v1' &&
          e.Bot !== 'capture-list-items-voice-v1' &&
          e.Bot !== 'seed-board-v1'
      )
      .map((e) => new BotSessionRowVM(this.rootStore, this, e))
      .sort((a, b) => {
        const aVal = a.title
        const bVal = b.title
        if (aVal < bVal) return 0
        return -1
      })
  }
}
