import React from 'react'
import { IonRouterOutlet, IonPage, IonSplitPane, IonSpinner } from '@ionic/react'
import { Route, withRouter } from 'react-router'
import ListItemsListPage from '../list-items/views/list/ListItemsListPage'
import { inject, observer } from 'mobx-react'
import SideMenu from '../side-menu/SideMenu'
import { Capacitor } from '@capacitor/core'
import CategoriesListPage from '../categories/views/list/CategoriesListPage'
import Header from '../header/Header'
import BoardSettingsPage from '../boards/views/BoardSettingsPage'
import RecipesListPage from '../recipes/views/list/RecipesListPage'
import RecipeCategoriesListPage from '../recipe-categories/views/RecipeCategoriesListPage'
import RecipeCategoryEditPage from '../recipe-categories/views/RecipeCategoryEditPage'
import RecipeEditPage from '../recipes/views/edit/RecipeEditPage'
import MealCategoryEditPage from '../meal-categories/views/edit/MealCategoryEditPage'
import MealCategoriesListPage from '../meal-categories/views/list/MealCategoriesListPage'
import MealsListPage from '../meals/views/list/MealsListPage'
import MealEditPage from '../meals/views/edit/MealEditPage'
import TabsBar from '../bottom-tabs/BottomTabs'
import ThemeGenPage from 'src/theme-gen/ThemeGenPage'
import CategoryEditPage from 'src/categories/views/edit/CategoryEditPage'
import ItemsSelectPage from 'src/items/views/list/ItemsSelectPage'
import ItemEditPage from 'src/items/views/edit/ItemEditPage'
import ItemsListPage from 'src/items/views/list/ItemsListPage'
import ThemesListPage from 'src/themes/views/ThemesListPage'
import BoardJoinModal from '../boards/views/BoardJoinModal'
import { AppStore } from '../stores/AppStore'
import AccountSettingsPage from '../user/views/AccountSettingsPage'
import SplashScreen from '../splash/SplashScreen'
import { App } from '@capacitor/app'
import SettingsPage from '../settings/views/SettingsPage'
import BoardNewModal from '../boards/views/BoardNewModal'
import RecipeImportPage from '../recipe-imports/views/RecipeImportPage'
import RecipeGeneratePage from '../recipe-generate/views/RecipeGeneratePage'
import BotSessionsListPage from '../bot-sessions/views/list/BotSessionsListPage'
import BotSessionEditPage from '../bot-sessions/views/edit/BotSessionEditPage'
import BotSessionNewPage from '../bot-sessions/views/new/BotSessionNewPage'

interface Props {
  appStore?: AppStore
  history?: any
  location?: any
}
@inject('appStore')
@observer
class Layout extends React.Component<Props, any> {
  ionMenu: any

  constructor(props) {
    super(props)
    this.props.appStore.setHistory(this.props.history)
  }

  componentWillUnmount() {
    if (Capacitor.getPlatform() !== 'web') {
      // Plugins.App.removeListener('backButton', () => this.handleGoBack())
      App.removeAllListeners()
    }
  }

  componentDidMount() {
    this.checkAuth()
    if (Capacitor.getPlatform() !== 'web') {
      App.addListener('backButton', () => this.handleGoBack())
    }
    setInterval(() => {
      if (this.props.appStore.bodyHeight === document.body.offsetHeight) return
      this.props.appStore.setBodyHeight(document.body.offsetHeight)
    }, 1000)
    window.onresize = () => {
      if (this.props.appStore.bodyHeight === document.body.offsetHeight) return
      this.props.appStore.setBodyHeight(document.body.offsetHeight)
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.checkAuth()
      this.onRouteChanged(this.props.location)
    }
  }

  handleGoBack() {
    this.props.appStore.handleGoBack(this.props.history)
  }

  onRouteChanged(location) {
    this.props.appStore.setCurrentRoute(location.pathname)
  }

  checkAuth() {
    const { isHydrated, isLoggedIn, currentRoute } = this.props.appStore
    let isAnonymousPage = false
    if (currentRoute.indexOf('/tno') >= 0) isAnonymousPage = true
    if (currentRoute === 'login') isAnonymousPage = true
    if (currentRoute === '/login') isAnonymousPage = true
    if (currentRoute === '/register') isAnonymousPage = true
    if (currentRoute === '/forgot-password') isAnonymousPage = true
    if (currentRoute === '/reset-password') isAnonymousPage = true
    setTimeout(() => {
      if (isHydrated && !isLoggedIn && !isAnonymousPage && currentRoute !== 'login' && currentRoute !== '/login') {
        console.log(currentRoute)
        console.log('going to login from: ' + currentRoute)
        this.props.appStore.hideSplashScreen()
        this.props.appStore.setCurrentRoute('/login')
        this.props.history.push('/login')
      } else if (!isHydrated) {
        setTimeout(() => this.checkAuth(), 250)
      } else {
        this.props.appStore.hideSplashScreen()
        this.props.appStore.checkNativeStatusBarColor()
      }
    }, 250)
  }

  render() {
    if (!this.props.appStore.isLoggedIn) return null
    if (this.props.location.pathname.toLowerCase() === '/login') return null
    if (this.props.location.pathname.toLowerCase() === '/register') return null
    if (this.props.location.pathname.toLowerCase() === '/forgot-password') return null
    if (!this.props.appStore.splashScreenHidden) return <SplashScreen />
    const { isSplitPaneVisible } = this.props.appStore

    return (
      <>
        {isSplitPaneVisible && <Header history={this.props.history} />}

        <IonSplitPane
          contentId='main'
          id='Layout'
          onIonSplitPaneVisible={(e) => {
            const isVisible = e.detail.visible
            this.props.appStore.setSplitPaneVisible(isVisible)
          }}
        >
          <SideMenu />

          <IonPage id='main'>
            {!isSplitPaneVisible && <Header history={this.props.history} />}
            <IonRouterOutlet>
              <Route path={'/'} component={ListItemsListPage} exact />
              <Route path='/accountsettings' component={AccountSettingsPage} exact />
              <Route path='/itemslist' component={ItemsListPage} exact />
              <Route path='/itemsselect' component={ItemsSelectPage} exact />
              <Route path='/categorieslist' component={CategoriesListPage} exact />
              <Route path='/categoryedit/:id' component={CategoryEditPage} exact />
              <Route path='/itemedit/:id' component={ItemEditPage} exact />
              <Route path='/recipeslist' component={RecipesListPage} exact />
              <Route path='/recipecategorieslist' component={RecipeCategoriesListPage} exact />
              <Route path='/recipecategoryedit/:id' component={RecipeCategoryEditPage} exact />
              <Route path='/boardsettings' component={BoardSettingsPage} exact />
              <Route path='/recipeedit/:id' component={RecipeEditPage} exact />
              <Route path='/recipeedit/:id/:name' component={RecipeEditPage} exact />
              <Route path='/recipeimport' component={RecipeImportPage} exact />
              <Route path='/mealcategorieslist' component={MealCategoriesListPage} exact />
              <Route path='/mealcategoryedit/:id' component={MealCategoryEditPage} exact />
              <Route path='/mealedit/:id/:date/:catid' component={MealEditPage} exact />
              <Route path='/mealedit/:id/:date/:catid/:recipeid' component={MealEditPage} exact />
              <Route path='/mealslist' component={MealsListPage} exact />
              <Route path='/mealedit/:id' component={MealEditPage} exact />
              <Route path='/themegen' component={ThemeGenPage} exact />
              <Route path='/themeslist' component={ThemesListPage} exact />
              <Route path='/settings' component={SettingsPage} exact />
              <Route path='/botsessionslist' component={BotSessionsListPage} exact />
              <Route path='/botsessionedit/:id' component={BotSessionEditPage} exact />
              <Route path='/botsessionnew/:id' component={BotSessionNewPage} exact />
            </IonRouterOutlet>
            <TabsBar />
            <BoardJoinModal />
            <BoardNewModal />
          </IonPage>
        </IonSplitPane>
      </>
    )
  }
}

export default withRouter(Layout)
