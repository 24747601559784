import { isEqual } from 'lodash'
import { observable, action, computed, makeObservable, reaction, when, runInAction, IReactionDisposer } from 'mobx'
import { RootStore } from 'src/stores/RootStore'
import { BotSession } from '../../aggregate/BotSession'
import { BotSessionsService } from '../../services/BotSessionsService'
import { BotSessionInteractionCardVM } from './BotSessionInteractionCardVM'
import { StorageFilesAudioService } from '../../../storage-files/services/StorageFilesAudioService'
import { ListItem } from '../../../list-items/aggregate/ListItem'
import { ListItemsService } from '../../../list-items/services/ListItemsService'
import { StorageFilesPhotoService } from '../../../storage-files/services/StorageFilesPhotoService'
import { Item } from '../../../items/aggregate/Item'
import { ItemsService } from '../../../items/services/ItemsService'

export class BotSessionEditVM {
  private rootStore: RootStore
  private reactionDisposers: IReactionDisposer[] = []
  private contentDiv: HTMLIonContentElement
  private parentRecordTable: string
  private parentRecordGuid: string
  private botName: string
  private description: string
  private onHide: () => void
  private botSessionsSvc: BotSessionsService
  private audioSvc: StorageFilesAudioService
  private botSessionGuid: string
  private inModal: boolean
  private title: string
  private isNew: boolean = false
  private uploaderButton: HTMLIonFabButtonElement
  private currentInteractionGuid: string
  private startTO: NodeJS.Timeout
  private scrollTO: NodeJS.Timeout

  constructor({
    rootStore,
    botName,
    botSession,
    title,
    description,
    parentRecordGuid,
    parentRecordTable,
    botSessionGuid,
    inModal,
    onHide,
  }: {
    rootStore?: RootStore
    botSessionGuid?: string
    botName?: string
    title?: string
    description?: string
    botSession?: BotSession
    parentRecordGuid?: string
    parentRecordTable?: string
    inModal?: boolean
    onHide?: () => void
  }) {
    makeObservable(this)
    this.rootStore = rootStore
    this.inModal = inModal
    this.botName = botName
    this.title = title
    this.description = description
    this.botSessionGuid = botSessionGuid
    this.onHide = onHide
    this.parentRecordTable = parentRecordTable
    this.parentRecordGuid = parentRecordGuid
    this.botSessionsSvc = new BotSessionsService(rootStore)
    this.audioSvc = new StorageFilesAudioService(rootStore)
    this.ensureBotSession(botSession)
    this.loadReactions()
    if (this.botSession.CanCaptureAudio) this.audioSvc.checkPermissions
  }

  @observable public deleteConfirmShown: boolean = false
  @observable public botSession: BotSession
  @observable public interactionCards: BotSessionInteractionCardVM[] = []
  @observable public spacerHeight: number = 0
  @observable public shown: boolean = false
  @observable public isCapturingAudio: boolean = false

  private ensureBotSession(botSession: BotSession) {
    if (!botSession) botSession = this.lookupBotSessionByParentRecord()
    if (!botSession) botSession = this.lookupBotSessionById()
    if (!botSession) botSession = this.createBotSession()
    this.setBotSession(botSession.clone())
    this.saveIfNew()
  }

  private loadReactions() {
    this.reactionDisposers.push(
      reaction(
        () => this.rootStore.botSessionsStore.get(this.botSession.BotSessionGuid),
        (botSession) => {
          this.setBotSession(botSession?.clone())
        }
      )
    )
  }

  private lookupBotSessionByParentRecord() {
    const found = this.rootStore.botSessionsStore.currentBoardRecords.find(
      (e) =>
        e.ParentRecordTable === this.parentRecordTable &&
        e.ParentRecordGuid == this.parentRecordGuid &&
        e.IsDeleted === false
    )
    return found
  }

  private lookupBotSessionById() {
    const found = this.rootStore.botSessionsStore.currentBoardRecords.find(
      (e) => e.BotSessionGuid === this.botSessionGuid
    )
    return found
  }

  private createBotSession() {
    const botSession = BotSession.create(this.rootStore.boardsStore.currentBoardId, this.botName, this.title)
    botSession.setDescription(this.description)
    botSession.setParentRecord(this.parentRecordTable, this.parentRecordGuid)
    this.isNew = true
    return botSession
  }

  private async saveIfNew() {
    if (!this.isNew) return
    if (!this.botSession.autoSave) return
    await this.botSessionsSvc.save(this.botSession.toDTO())
  }

  @action
  private setBotSession(botSession: BotSession) {
    if (!botSession) return
    if (botSession.IsDeleted) this.ensureBotSession(undefined)
    let skipUpdate = false
    // if (this.botSession?.LastUpdatedDateTime.toDateString() === botSession.LastUpdatedDateTime.toDateString()) {
    //   skipUpdate = true
    // }
    // if (this.botSession?.Interactions.length !== botSession.Interactions.length) skipUpdate = false
    if (isEqual(this.botSession, botSession)) skipUpdate = true
    if (skipUpdate) {
      console.log('skipping update')
      return
    }
    if (this.botSession) {
      console.log(
        'a',
        botSession.LastUpdatedDateTime.toDateString(),
        'b',
        this.botSession?.LastUpdatedDateTime.toDateString()
      )
    }
    this.botSession = botSession
    this.loadCards()
    this.updateSpacerHeight()
    // this.scrollToBottom()
    setTimeout(() => this.updateSpacerHeight(), 500)
  }

  @action
  private loadCards() {
    const hasNewCard = this.interactionCards.length < this.botSession.Interactions.length
    this.interactionCards = this.botSession.Interactions.filter((e) => {
      if (!e.AdaptiveCardTemplateName) return false
      return true
    })
      .map((e) => new BotSessionInteractionCardVM(this.rootStore, this, this.botSession, e))
      // .filter((e) => {
      //   if (this.botSession.Bot === 'recipe-from-pantry-v1' && !e.isLastCard) return false
      //   return true
      // })
      .sort((a, b) => (a.rank < b.rank ? -1 : 0))
    if (hasNewCard) this.scollLastCardIntoView()
  }

  @action
  public setContentDiv(div: HTMLIonContentElement | undefined) {
    this.contentDiv = div
    this.scrollToBottom()
  }

  public scrollToBottom() {
    // if (!this.contentDiv) {
    //   setTimeout(() => this.scrollToBottom(), 100)
    //   return
    // }
    // this.contentDiv.scrollToBottom()
    // this.scollLastCardIntoView()
  }

  @computed
  private get lastCard() {
    const maxRank = Math.max(...this.interactionCards.map((e) => e.rank))
    return this.interactionCards.find((e) => e.rank === maxRank)
  }

  private scollLastCardIntoView() {
    if (this.scrollTO) clearTimeout(this.scrollTO)
    this.scrollTO = setTimeout(() => {
      this.lastCard?.scrollIntoView()
    }, 300)
  }

  public dispose() {
    this.reactionDisposers.forEach((d) => d())
  }

  @computed
  public get pageTitle(): string {
    return this.botSession.Title
  }

  @action
  public updateSpacerHeight(): number {
    if (!this.lastCard) return
    if (!this.lastCard.hasNativeElement) return
    if (!this.lastCard.height) {
      console.log('height not set, but there is a native element')
      return
    }
    let height = document.body.clientHeight - this.lastCard.height
    if (this.isProcessing) height -= 120
    height -= 80
    const changed = height !== this.spacerHeight
    this.spacerHeight = height
    if (changed) this.scollLastCardIntoView()
  }

  @action
  public showDeleteConfirm() {
    this.deleteConfirmShown = true
  }

  public startNewSession() {
    this.rootStore.appStore.navigateTo('/botsessionnew/' + this.botSession.Bot)
  }

  @action
  public hideDeleteConfirm() {
    this.deleteConfirmShown = false
  }

  @action
  public async handleSubmit(interactionGuid: string, adaptiveCardAnswer: any) {
    this.botSession.setAnswer(interactionGuid, adaptiveCardAnswer)
    this.scrollToBottom()
    await this.botSessionsSvc.save(this.botSession.toDTO())
  }

  @action
  public async handleGenerateRecipeFromPhoto(interactionGuid: string) {
    this.botSession.setGenerateRecipeFromPhotoRequsted(interactionGuid)
    await this.botSessionsSvc.save(this.botSession.toDTO())
  }

  @action
  public async handleSelectOption(interactionGuid: string, option: string) {
    this.botSession.setSelectedOption(interactionGuid, option)
    const int = this.botSession.getInteraction(interactionGuid)
    int.markAsNotAnswered()
    if (option.toLowerCase() !== 'other') {
      this.botSession.markAsAnswered(interactionGuid, option)
    }
    await this.botSessionsSvc.save(this.botSession.toDTO())
  }

  @action
  public async handleToggleIngredient(interactionGuid: string, ingredient: string) {
    this.botSession.toggleSelectedIngredient(interactionGuid, ingredient)
    await this.botSessionsSvc.save(this.botSession.toDTO())
  }

  @action
  public async handleRequestRecipes(interactionGuid: string, other: string) {
    if (other) {
      this.botSession.markAsAnswered(interactionGuid, other)
    }
    this.botSession.markAsRecipesRequested(interactionGuid)
    await this.botSessionsSvc.save(this.botSession.toDTO())
  }

  @action
  public async handleGenerateRecipe(interactionGuid: string, recipeName: string) {
    this.botSession.setRecipeToGenerate(interactionGuid, recipeName)
    await this.botSessionsSvc.save(this.botSession.toDTO())
  }

  @action
  public async handleMarkAsAnswered(interactionGuid: string, finalAnswer: string) {
    this.botSession.markAsAnswered(interactionGuid, finalAnswer)
    await this.botSessionsSvc.save(this.botSession.toDTO())
  }

  @action
  public async handleMakeChangesToRecipe(interactionGuid: string) {
    this.botSession.markAsAnswered(interactionGuid, 'Make Changes')
    await this.botSessionsSvc.save(this.botSession.toDTO())
  }

  @action
  public async handleAddUploadImageInteraction() {
    this.botSession.addUploadImageInteraction()
    await this.botSessionsSvc.save(this.botSession.toDTO())
  }

  @action
  public async handleSaveRecipe(interactionGuid: string) {
    this.botSession.setSaveRecipeRequested(interactionGuid)
    await this.botSessionsSvc.save(this.botSession.toDTO())
  }

  @action
  public async handleSaveUrl(interactionGuid: string, url: string) {
    this.botSession.setGetRecipeFromUrlRequested(interactionGuid, url)
    await this.botSessionsSvc.save(this.botSession.toDTO())
  }

  @action
  public async handleGenerateMoreImages() {
    this.botSession.addPendingImageInteraction()
    this.loadCards()
    await this.botSessionsSvc.save(this.botSession.toDTO())
  }

  @action
  public async handlAttachImage(interactionGuid: string) {
    const intx = this.botSession.getInteraction(interactionGuid)
    if (this.botSession.Bot === 'generate-item-image-v1') {
      this.rootStore.itemsStore.editVM.addAttachment(intx.StorageFile.toDTO())
      return
    }
    this.rootStore.recipesStore.editVM.addAttachment(intx.StorageFile.toDTO())
  }

  public async handleOpenRecipe(interactionGuid: string) {
    const int = this.botSession.getInteraction(interactionGuid)
    if (!int) return
    this.rootStore.appStore.navigateTo('/recipeedit/' + int.details.RecipeGuid)
    await this.botSessionsSvc.save(this.botSession.toDTO())
  }

  @action
  public async handleOpenCamera(interactionGuid: string) {
    const int = this.botSession.getInteraction(interactionGuid)
    if (!int) return
    this.currentInteractionGuid = interactionGuid
    // this.uploaderButton.click()
    await this.takePhoto(interactionGuid)
  }

  @action
  public setDescription(val: string) {
    this.botSession.setDescription(val)
  }

  @computed
  public get isProcessing(): boolean {
    if (this.botSession.Interactions.length === 0) return true
    return this.botSession.Interactions.some((e) => e.IsProcessing)
  }

  public delete() {
    const svc = new BotSessionsService(this.rootStore)
    svc.delete(this.botSession.toDTO())
    this.rootStore.appStore.handleGoBack()
  }

  public get isIos(): boolean {
    return this.rootStore.appStore.isIos
  }

  @action
  public setShown(val: boolean): void {
    this.shown = val
  }

  @action
  public hide() {
    this.shown = false
    this.onHide()
  }

  public done(): void {
    this.hide()
  }

  public cancel(): void {
    this.hide()
  }

  public goBack(): void {
    this.hide()
  }

  @action
  public async startAudioCapture() {
    if (this.startTO) clearTimeout(this.startTO)
    this.startTO = setTimeout(() => this.actuallyStartCapture(), 100)
  }

  public async actuallyStartCapture() {
    if (this.isCapturingAudio) return
    await this.audioSvc.startCapture()
    runInAction(() => (this.isCapturingAudio = true))
  }

  public async stopAudioCapture() {
    if (!this.isCapturingAudio) return
    setTimeout(() => this.actuallyStopCapture(), 100)
  }

  public async actuallyStopCapture() {
    let dto
    try {
      dto = await this.audioSvc.stopCapture()
    } catch (e) {
      console.error(e)
    }
    if (!dto) return
    this.botSession.addStorageFileInteraction(dto.StorageFileGuid)
    this.botSessionsSvc.save(this.botSession.toDTO())
    runInAction(() => (this.isCapturingAudio = false))
  }

  public toggleListItem(itemGuid: string) {
    const existing = this.rootStore.listItemsStore.getByItemGuid(itemGuid)
    if (existing) {
      const svc = new ListItemsService(this.rootStore)
      svc.delete(existing.toDTO())
      return
    }
    const listItem = ListItem.create(this.rootStore.boardsStore.currentBoardId, itemGuid)
    listItem.increaseQuantity()
    const svc = new ListItemsService(this.rootStore)
    svc.save(listItem.toDTO())
  }

  public addNewItem(itemName: string, categoryGuid: string) {
    const item = Item.create(this.rootStore.boardsStore.currentBoardId)
    item.setName(itemName)
    item.setCategory(categoryGuid)
    const itemsSvc = new ItemsService(this.rootStore)
    itemsSvc.saveItem(item.toDTO())
    const listItem = ListItem.create(this.rootStore.boardsStore.currentBoardId, item.ItemGuid)
    listItem.increaseQuantity()
    const svc = new ListItemsService(this.rootStore)
    svc.save(listItem.toDTO())
  }

  @computed
  public get canCaptureAudio(): boolean {
    return this.botSession.CanCaptureAudio
  }

  @computed
  public get canUploadPhoto(): boolean {
    return this.botSession.CanUploadPhoto
  }

  @action
  public async takePhoto(interactionGuid: string = undefined) {
    if (!interactionGuid) interactionGuid = this.currentInteractionGuid
    const int = this.botSession.getInteraction(interactionGuid)
    int.markAsProcessing()
    const svc = new StorageFilesPhotoService(this.rootStore)
    const dto = await svc.takePhoto()
    runInAction(() => {
      const int = this.botSession.getInteraction(interactionGuid)
      if (this.botSession.Bot !== 'recipe-from-pantry-v1') int.markAsNotProcessing()
      if (!dto) return
      int.setStorageFile(dto)
      this.botSessionsSvc.save(this.botSession.toDTO())
    })
  }

  @computed
  public get listHeightPx(): string {
    if (this.inModal) return this.rootStore.appStore.listHeightForModalPx
    return this.rootStore.appStore.listHeight + 10 + 'px'
  }

  @action
  public setUploaderButton(e: HTMLIonFabButtonElement) {
    if (!this.botSession.CanUploadPhoto) return
    if (!e) return
    this.uploaderButton = e
  }
}
