import React, { useEffect, useLayoutEffect, useRef } from 'react'
import { inject, useLocalObservable } from 'mobx-react'
import { AdaptiveCardVM } from './AdaptiveCardVM'
import { RootStore } from '../../stores/RootStore'

interface Props {
  cardKey: string
  rootStore?: RootStore
  card: any
  data?: any
  onSubmit: (card, actionData) => void
}

const AdaptiveCard: React.FC<Props> = ({ cardKey, rootStore, card, onSubmit, data }) => {
  const divRef = useRef<HTMLFormElement>(null)
  const vm = useLocalObservable(() => new AdaptiveCardVM(rootStore, card, onSubmit, data))
  useEffect(() => {
    // use these 2 get set up in the adaptive cards designer
    console.log('data', data)
    console.log('card', card)
    vm.setCard(card)
    vm.setData(data)
    if (divRef.current) {
      divRef.current.innerHTML = ''
      divRef.current.appendChild(vm.getRenderedCard())
    }
  }, [cardKey, card, data])
  return (
    <form
      ref={divRef}
      onSubmit={(e) => {
        e.preventDefault()
        vm.submitCard()
      }}
    ></form>
  )
}

export default inject('rootStore')(AdaptiveCard)
