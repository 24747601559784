import React from 'react'
import { observer } from 'mobx-react'
import { ListItemRowVM } from '../../view-models/ListItemRowVM'
import StorageFileImage from '../../../storage-files/views/StorageFileImage'
import { IonIcon } from '@ionic/react'
import { camera, cameraOutline, cameraSharp } from 'ionicons/icons'
import { EvilIcons, Feather, Foundation } from 'react-web-vector-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCameraRetro, faFile, faPhotoVideo } from '@fortawesome/free-solid-svg-icons'

interface Props {
  row: ListItemRowVM
  style: any
}

const ListItemRow: React.FC<Props> = ({ row, style }) => {
  const style2 = {
    borderLeftColor: row.categoryColor,
    ...style,
  }

  const renderNotes = () => {
    if (!row.hasNotes) return
    return (
      <div className='notes'>
        {row.notes}
        {row.notes !== '' && <span>&nbsp;</span>}
        {renderFutureItems()}
      </div>
    )
  }

  const renderFutureItems = () => {
    return row.futureItems.map((e) => e.fullDescription).join(' ')
  }

  const renderGottenIcon = () => {
    if (!row.isGotten) return
    return (
      <div
        className='icon-container checked'
        onMouseDown={(e) => e.stopPropagation()}
        onClick={(e) => {
          e.stopPropagation()
          row.toggleGotten()
        }}
      >
        <Feather name='check-circle' size={22} />
      </div>
    )
  }

  const renderNotGottenIcon = () => {
    if (row.isGotten) return
    return (
      <div
        className='icon-container'
        onMouseDown={(e) => e.stopPropagation()}
        onClick={(e) => {
          e.stopPropagation()
          row.toggleGotten()
        }}
      >
        <Feather name='circle' size={22} />
      </div>
    )
  }

  const renderRightOptions = () => {
    return (
      <div id='DeleteContainer' onClick={() => row.deleteFromShoppingList()}>
        DELETE
      </div>
    )
  }

  const renderImage = () => {
    if (!row.hasImage) return
    if (!row.itemItemsFeatureEnabled) return
    // return <StorageFileImage storageFile={row.image} />
    // return <IonIcon icon={cameraOutline} className='has-photo' size='large' />
    return (
      // <div className='has-photo'>
      <StorageFileImage storageFile={row.image} height={41} />
      // {/* <EvilIcons name='camera' /> */}
      // </div>
    )
  }

  return (
    <div style={style2} id='ListItemRow'>
      <div
        id='MainContainer'
        ref={(e) => row.setSlidingRef(e)}
        onClick={() => row.click()}
        className={row.className}
        style={{ backgroundColor: row.backgroundColor }}
      >
        {renderGottenIcon()}
        {renderNotGottenIcon()}
        <div className='name'>
          {row.name}
          {renderNotes()}
        </div>
        {renderImage()}
      </div>
      {renderRightOptions()}
    </div>
  )
}

export default observer(ListItemRow)
