import { RootStore } from 'src/stores/RootStore'
import { computed, action, observable, makeObservable } from 'mobx'
import { BotSessionsListVM } from './BotSessionsListVM'
import { BotSession } from '../../aggregate/BotSession'

export class BotSessionRowVM {
  private rootStore: RootStore
  private botSession: BotSession
  private listVM: BotSessionsListVM

  constructor(rootStore: RootStore, listVM: BotSessionsListVM, botSession: BotSession) {
    this.rootStore = rootStore
    this.botSession = botSession
    this.listVM = listVM
    makeObservable(this)
  }

  public type: string = 'item'
  @observable public keepUp: boolean = false
  @observable public keepDown: boolean = false

  @computed
  public get title(): string {
    let val = this.convertFromUTC(this.botSession.CreatedDateTime).toLocaleString()
    if (this.botSession.Title) val += ' - ' + this.botSession.Title
    return val
  }

  private convertFromUTC(date: Date): Date {
    return new Date(date.getTime() + date.getTimezoneOffset() * 60000)
  }

  @computed
  public get descriptionForSort(): string {
    return this.title.toLowerCase()
  }

  public get botSessionGuid(): string {
    return this.botSession.BotSessionGuid
  }

  @action
  public openBotSession() {
    this.rootStore.appStore.history.push('/botSessionedit/' + this.botSessionGuid)
  }
}
