import { observer } from 'mobx-react'
import Loading02 from '../../assets/images/loading02.gif'
import Loading03 from '../../assets/images/loading03.gif'

interface Props {
  type: 'spinner' | 'listening'
  isVisible: boolean
}

const SpinnerV2: React.FC<Props> = (props) => {
  if (!props.isVisible) return null

  const renderSpinner = () => {
    return (
      <div id='SpinnerV2'>
        <img src={Loading02} alt='Loading...' className='loading-spinner' />
      </div>
    )
  }

  const renderListeningIndicator = () => {
    return (
      <div id='SpinnerV2'>
        <img src={Loading03} alt='Loading...' className='listening-spinner' />
      </div>
    )
  }

  return (
    <>
      {props.type === 'spinner' && renderSpinner()}
      {props.type === 'listening' && renderListeningIndicator()}
    </>
  )
}

export default observer(SpinnerV2)
