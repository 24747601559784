import React from 'react'
import {
  IonList,
  IonItem,
  IonButton,
  IonModal,
  IonLabel,
  IonInput,
  IonTitle,
  IonToolbar,
  IonCardHeader,
  IonCardContent,
  IonCard,
} from '@ionic/react'
import { observer, inject } from 'mobx-react'
import { RecipesStore } from '../../store/RecipesStore'

interface Props {
  recipesStore?: RecipesStore
}

const RecipeCategoryNewModal: React.FC<Props> = ({ recipesStore }) => {
  const { editVM } = recipesStore
  if (!editVM) return null
  const vm = editVM.categoryNewVM
  if (!vm) return null

  const handleSave = e => {
    e.preventDefault()
    vm.save()
  }

  return (
    <IonModal id='RecipeCategoryNewModal' isOpen={vm.shown} showBackdrop={true} onDidDismiss={() => vm.hide()}>
      <IonCard>
        <IonCardHeader>
          <IonTitle>New Recipe Category</IonTitle>
        </IonCardHeader>
        <IonCardContent>
          <form onSubmit={e => handleSave(e)}>
            <IonList>
              <IonItem lines={'inset'}>
                <IonLabel position='floating'>Name</IonLabel>
                <IonInput
                  type='text'
                  name={Math.random().toString()}
                  value={vm.name}
                  onIonChange={(e: any) => vm.setName(e.target.value)}
                  clearOnEdit={true}
                  ref={e => vm.setInputRef(e)}
                />
              </IonItem>
              <div id='buttons'>
                <IonButton
                  type='button'
                  size='small'
                  color='secondary'
                  onClick={e => {
                    e.stopPropagation()
                    vm.cancel()
                  }}
                >
                  Cancel
                </IonButton>
                <IonButton type='submit' size='small' color='secondary' disabled={!vm.isValid}>
                  Create
                </IonButton>
              </div>
            </IonList>
          </form>
        </IonCardContent>
      </IonCard>
    </IonModal>
  )
}

export default inject('recipesStore')(observer(RecipeCategoryNewModal))
