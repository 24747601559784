import agent from '../../AgentV2'
import { RootStore } from '../../stores/RootStore'
import { IStorageFileDTO } from '../dtos/IStorageFileDTO'
import { ActionTypeEnum } from '../../actions/enum/ActionTypeEnum'
import { StorageFileUploadService } from './StorageFileUploadService'
import { StorageFilesReadService } from './StorageFilesReadService'
import { StorageFile } from '../aggregate/StorageFile'

export class StorageFilesService {
  constructor(private rootStore: RootStore) {}

  public save(storageFile: StorageFile | IStorageFileDTO) {
    let dto = storageFile
    if (storageFile instanceof StorageFile) {
      dto = storageFile.toDTO()
      if (storageFile.isNew) {
        this.rootStore.actionsStore.addAction(ActionTypeEnum.STORAGEFILE_UPLOAD, dto)
      }
    }
    this.rootStore.storageFilesStore.updateFromServer(dto)
  }

  public async uploadBase64(base64: string, dto: IStorageFileDTO): Promise<IStorageFileDTO> {
    const uploadSvc = new StorageFileUploadService(this.rootStore)
    const savedDTO = await uploadSvc.uploadBase64(base64, dto)
    this.rootStore.storageFilesStore.updateFromServer(savedDTO)
    return savedDTO
  }

  public async uploadOfflineStorageFile(dto: IStorageFileDTO): Promise<IStorageFileDTO> {
    const uploadSvc = new StorageFileUploadService(this.rootStore)
    const savedDTO = await uploadSvc.uploadOfflineStorageFile(dto)
    this.rootStore.storageFilesStore.updateFromServer(savedDTO)
    return savedDTO
  }

  public async saveOnServer(dto: IStorageFileDTO) {
    const savedDTO = await agent.StorageFiles.save(dto)
    this.rootStore.storageFilesStore.updateFromServer(savedDTO)
  }

  public delete(dto: IStorageFileDTO) {
    dto.IsDeleted = true
    this.save(dto)
  }
}
