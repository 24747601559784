import { action, computed, observable } from 'mobx'
import { RootStore } from '../../stores/RootStore'
import { StorageFile } from '../aggregate/StorageFile'
import { StorageFileImageVM } from './StorageFileImageVM'
import Loading02 from '../../assets/images/loading02.gif'

export class ImagesViewerVM {
  private images: StorageFileImageVM[]
  private onClose: () => void
  private storageFiles: StorageFile[]
  private rootStore: RootStore

  constructor({
    rootStore,
    isOpen,
    onClose,
    storageFiles,
  }: {
    rootStore: RootStore
    isOpen: boolean
    onClose: () => void
    storageFiles: StorageFile[]
  }) {
    this.rootStore = rootStore
    this.storageFiles = storageFiles
    this.onClose = onClose
    this.isOpen = isOpen
    this.loadImages()
  }

  @observable public initialIndex: number = 0
  @observable public isOpen: boolean = true

  @action
  public setOpen(isOpen: boolean) {
    this.isOpen = isOpen
  }

  @action
  public setIndex(val: number) {
    this.initialIndex = val
  }

  @action
  public handleClose(): void {
    this.isOpen = false
    this.onClose()
  }

  @action
  private loadImages() {
    this.images = this.storageFiles.map((sf) => new StorageFileImageVM(this.rootStore, sf))
  }

  @computed
  public get urls(): string[] {
    return this.images.map((i) => {
      if (i.isLoading) return Loading02
      return i.url
    })
  }
}
