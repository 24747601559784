import { Directory, Filesystem } from '@capacitor/filesystem'
import { StorageFile } from '../aggregate/StorageFile'
import { IStorageFileDTO } from '../dtos/IStorageFileDTO'

export class StorageFilesReadService {
  private ITEM_IMAGES_PATH = 'item-images'
  private static cachedImages: { [key: string]: string } = {}

  public async exists(storageFile: StorageFile | IStorageFileDTO) {
    try {
      await Filesystem.stat({
        path: this.ITEM_IMAGES_PATH + '/' + storageFile.UniqueFileName,
        directory: Directory.Data,
      })
      return true
    } catch (e) {
      return false
    }
  }

  public hasCachedImage(storageFile: StorageFile | IStorageFileDTO) {
    return !!StorageFilesReadService.cachedImages[storageFile.UniqueFileName]
  }

  public getCacheImage(storageFile: StorageFile | IStorageFileDTO) {
    return StorageFilesReadService.cachedImages[storageFile.UniqueFileName]
  }

  public async getDataUrl(storageFile: StorageFile | IStorageFileDTO) {
    if (this.hasCachedImage(storageFile)) return StorageFilesReadService.cachedImages[storageFile.UniqueFileName]
    const exists = await this.exists(storageFile)
    if (!exists) {
      console.log('not exists', storageFile.UniqueFileName)
      return undefined
    }
    console.log('reading file')
    const file = await Filesystem.readFile({
      path: this.ITEM_IMAGES_PATH + '/' + storageFile.UniqueFileName,
      directory: Directory.Data,
    })
    StorageFilesReadService.cachedImages[storageFile.UniqueFileName] = `data:image/jpeg;base64,${file.data}`
    return `data:image/jpeg;base64,${file.data}`
  }
}
