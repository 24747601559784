import { RootStore } from 'src/stores/RootStore'
import { observable, action, runInAction, computed, makeObservable } from 'mobx'
import agent from '../../Agent'
import BoardInvitation from '../../board-invitations/aggregate/BoardInvitation'
import * as Sentry from '@sentry/browser'
import { SignInWithApple, SignInWithAppleOptions, SignInWithAppleResponse } from '@capacitor-community/apple-sign-in'
import { IGuestRegistrationResult } from '../interfaces/IGuestRegistrationResult'
import { ILoginResult } from '../interfaces/ILoginResult'
import { Device } from '@capacitor/device'
import { SocialLogin, LoginResult, GoogleLoginResponse } from '@capgo/capacitor-social-login'

export class LoginVM {
  private rootStore: RootStore
  private to: NodeJS.Timeout
  private DEBUG: boolean = false

  constructor(rootStore) {
    makeObservable(this)
    this.rootStore = rootStore
    // this.checkForInput()
    this.loadBoardInvitation()
    if (process.env.REACT_APP_IS_DEV_MODE === '1') this.DEBUG = true
  }

  @observable public isProcessing = false
  @observable public errorMsg: string = ''
  @observable public email: string = ''
  @observable public password: string = ''
  @observable public hasEmail: boolean = false
  @observable public hasPassword: boolean = false
  @observable public loginTried: boolean = false
  @observable public boardInvitation: BoardInvitation = null
  @observable public acceptBoardInvitation: boolean = false
  @observable public showGoogleSpinner: boolean = false
  @observable public showAppleSpinner: boolean = false
  @observable public showGuestSpinner: boolean = false

  @action
  public toggleAcceptBoardInvitation() {
    this.acceptBoardInvitation = !this.acceptBoardInvitation
  }

  @action
  public async loadBoardInvitation() {
    const urlParams = new URLSearchParams(window.location.search)
    const id = urlParams.get('inv')
    if (!id) return
    this.boardInvitation = await agent.BoardInvitations.get(id)
    if (this.boardInvitation) {
      runInAction(() => (this.acceptBoardInvitation = true))
    }
  }

  @action
  private checkForInput() {
    // this.to = setTimeout(() => {
    //   runInAction(() => {
    //     try {
    //       const email = document.getElementById('email').getElementsByTagName('input')[0].value
    //       const pw = document.getElementById('password').getElementsByTagName('input')[0].value
    //       this.setEmail(email)
    //       this.setPassword(pw)
    //     } catch (e) {}
    //   })
    // }, 2000)
  }

  @computed
  public get isIos(): boolean {
    return this.rootStore.appStore.isIos
  }

  @computed
  public get canSignInWithApple(): boolean {
    if (this.rootStore.appStore.isAndroidNative) return false
    return true
  }

  @computed
  public get spinnerName(): string {
    if (this.rootStore.appStore.isIos) return 'cirles'
    return 'crescent'
  }

  @computed
  public get showSpinner() {
    return this.isProcessing
  }

  @action
  public async submit() {
    if (this.isProcessing) {
      console.log('nope')
      return
    }
    this.isProcessing = true
    const email = document.getElementById('email').getElementsByTagName('input')[0].value
    const pw = document.getElementById('password').getElementsByTagName('input')[0].value
    this.setEmail(email)
    this.setPassword(pw)
    const success = await this.tryLogin()
    if (success) {
      await this.processAcceptBoardInvitation()
      this.rootStore.loadData('user logged in')
      this.goToHomeAfterSuccess()
    }
  }

  @action
  public async processAcceptBoardInvitation() {
    if (this.boardInvitation && this.acceptBoardInvitation) {
      const postData = { BoardInvitationGuid: this.boardInvitation.BoardInvitationGuid }
      await agent.BoardInvitations.accept(postData)
    }
  }

  @action
  public setEmail(email) {
    this.hasEmail = true
    this.errorMsg = undefined
    this.email = email.trim()
  }

  @action
  public setPassword(password) {
    this.hasPassword = true
    this.errorMsg = undefined
    this.password = password
  }

  @action
  public goToRegister() {
    let url = '/register'
    if (this.boardInvitation) url += '?inv=' + this.boardInvitation.BoardInvitationGuid
    this.rootStore.appStore.navigateTo(url)
  }

  @action
  public goToForgotPassword() {
    const vm = this.rootStore.userStore.forgotPasswordVM
    if (vm) vm.setEmail(this.email)
    this.rootStore.appStore.navigateTo('/forgot-password')
  }

  @action
  public async tryLogin(): Promise<boolean> {
    this.errorMsg = undefined
    this.rootStore.appStore.setToken(null)
    if (this.email === '') {
      this.errorMsg = 'Please enter a valid email address'
      this.isProcessing = false
      return false
    }
    if (this.password === '') {
      this.errorMsg = 'Please enter a password'
      this.isProcessing = false
      return false
    }
    try {
      const result: ILoginResult = await agent.Auth.login(this.email, this.password)
      if (result.Success && result.Token) {
        this.rootStore.appStore.setToken(result.Token)
        this.rootStore.appStore.firebaseSvc.logEvent('login', { method: 'email' })
        return true
      } else {
        const msg = result.Errors.join('. ')
        this.errorMsg = msg
        this.isProcessing = false
        return false
      }
    } catch (err) {
      const msg = 'Unknown error. Please try again shortly.'
      console.error(err)
      this.isProcessing = false
      this.errorMsg = msg
      throw err
    }
  }

  @action
  public async continueWithGoogle() {
    this.rootStore.authStore.setGoogleLoginInfo(null)
    if (this.DEBUG) console.log('Starting Google Login')
    let result: LoginResult = undefined
    this.showGoogleSpinner = true
    try {
      await SocialLogin.initialize({
        google: {
          webClientId: '151188122140-glpdjmvcolr8pqc52g4i5ip8kjaelc6i.apps.googleusercontent.com',
          iOSClientId: '151188122140-71o0ots9m80mif915a95e0giruhrvkm4.apps.googleusercontent.com'
        },
      })
      result = await SocialLogin.login({
        provider: 'google',
        options: {
          scopes: ['email', 'profile'],
        },
      })
    } catch (e) {
      runInAction(() => (this.showGoogleSpinner = false))
      console.error(e)
      return
    }
    if (this.DEBUG) console.log(result)
    if (result.result.profile.email) {
      this.rootStore.authStore.setGoogleLoginInfo(result)
      const success = await this.tryGoogleLogin(result.result as GoogleLoginResponse)
      if (success) {
        await this.processAcceptBoardInvitation()
        this.rootStore.loadData('user logged in')
        this.goToHomeAfterSuccess()
      } else {
        this.showGoogleSpinner = false
        this.rootStore.authStore.loadRegistrationVM()
        this.rootStore.appStore.navigateTo('/register')
      }
    }
  }

  @action
  private async tryGoogleLogin(googleResponse: GoogleLoginResponse): Promise<boolean> {
    this.errorMsg = undefined
    this.rootStore.appStore.setToken(null)
    try {
      const result = await agent.Auth.tryGoogleLogin({
        userName: googleResponse.profile.name,
        firstName: googleResponse.profile.givenName,
        lastName: googleResponse.profile.familyName,
        email: googleResponse.profile.email,
        googleUserId: googleResponse.profile.id,
      })
      if (this.DEBUG) console.log(result)
      if (result !== 'not_found') {
        this.rootStore.appStore.setToken(result)
        this.rootStore.appStore.firebaseSvc.logEvent('login', { method: 'google' })
        return true
      }
      return false
    } catch (err) {
      let msg = 'Unknown Error'
      if (err.toString().indexOf('Request has been terminated')) msg = 'App offline. Please try again shortly.'
      console.error(err)
      console.log('LOGIN FAILED: ' + err.toString())
      this.isProcessing = false
      this.errorMsg = msg
      return false
    }
  }

  @action
  private async tryAppleLogin(appleUserToken: string): Promise<boolean> {
    this.errorMsg = undefined
    this.rootStore.appStore.setToken(null)
    try {
      const result: any = await agent.Auth.tryAppleLogin(
        appleUserToken,
        this.rootStore.authStore.appleLoginInfoUserName
      )
      if (result.Email) this.rootStore.authStore.appleLoginInfo.email = result.Email
      if (result.GroceryBoardToken) {
        this.rootStore.appStore.setToken(result.GroceryBoardToken)
        this.rootStore.appStore.firebaseSvc.logEvent('login', { method: 'apple' })
        return true
      }
      return false
    } catch (err) {
      let msg = 'Unknown Error'
      if (err.toString().indexOf('Request has been terminated')) msg = 'App offline. Please try again shortly.'
      console.error(err)
      console.log('LOGIN FAILED: ' + err.toString())
      this.isProcessing = false
      this.errorMsg = msg
      throw err
    }
  }

  @action
  public async continueWithApple() {
    this.rootStore.authStore.setAppleLoginInfo(null)
    let options: SignInWithAppleOptions = {
      clientId: 'io.groceryboard.web',
      redirectURI: 'https://app.groceryboard.io/login',
      scopes: 'email name',
      state: '12345',
      nonce: 'nonce',
    }
    const result: SignInWithAppleResponse = await SignInWithApple.authorize(options)
    if (result.response && result.response.identityToken) {
      this.showAppleSpinner = true
      this.rootStore.authStore.setAppleLoginInfo(result.response)
      console.log(result)
      const success = await this.tryAppleLogin(result.response.identityToken)
      if (success) {
        await this.processAcceptBoardInvitation()
        this.rootStore.loadData('user logged in')
        this.goToHomeAfterSuccess()
      } else {
        this.showAppleSpinner = false
        this.isProcessing = false
        this.rootStore.authStore.loadRegistrationVM()
        this.rootStore.appStore.navigateTo('/register')
      }
    }
  }

  @action
  public async continueAsGuest() {
    this.showGuestSpinner = true
    const id = await Device.getId()
    console.log(id)
    const frm = {
      UUID: id.identifier,
    }
    const result: IGuestRegistrationResult = await agent.Auth.tryGuestRegister(frm)
    console.log(result)
    if (result.Success && result.Token) {
      this.rootStore.appStore.setToken(result.Token)
      await this.processAcceptBoardInvitation()
      this.rootStore.loadData('user logged in')
      this.goToHomeAfterSuccess()
    } else {
      this.showGuestSpinner = false
      this.isProcessing = false
      this.errorMsg = result.Errors.join(' ')
    }
  }

  @action
  private goToHomeAfterSuccess() {
    setTimeout(() => {
      runInAction(() => {
        this.isProcessing = false
        this.showGuestSpinner = false
        this.showAppleSpinner = false
        this.showGoogleSpinner = false
        this.rootStore.appStore.themeGenVM.loadTheme('system')
        this.rootStore.appStore.navigateTo('/')
      })
    }, 2000)
  }
}
