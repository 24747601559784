import React from 'react'
import { IonButton, IonIcon, IonPopover, IonItem, IonAlert, IonSpinner } from '@ionic/react'
import { observer, inject } from 'mobx-react'
import { refresh, ellipsisVertical } from 'ionicons/icons'
import { MaterialCommunityIcons, EvilIcons, Feather } from 'react-web-vector-icons'
import { faAngleDoubleDown, faAngleDoubleUp, faPrint } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ListItemsStore } from '../../store/ListItemsStore'

interface Props {
  listItemsStore?: ListItemsStore
}

const ListItemsListPageMoreMenu: React.FC<Props> = ({ listItemsStore }) => {
  const vm = listItemsStore.listVM

  const renderClearCompletedConfirm = () => {
    return (
      <IonAlert
        isOpen={vm.clearCompletedConfirmShown}
        onDidDismiss={() => vm.hideClearCompletedConfirm()}
        header={'Clear Completed'}
        message={'Are you sure you wish to clear the items gotten?'}
        buttons={[
          {
            text: 'No',
            role: 'cancel',
            cssClass: 'primary',
            handler: () => vm.hideClearCompletedConfirm(),
          },
          {
            text: 'Yes',
            handler: () => vm.clearCompleted(),
          },
        ]}
      />
    )
  }

  const renderSweepIcon = (pos: 'left' | 'right' = 'right') => {
    if (pos === 'right' && vm.isIos) return
    if (pos === 'left' && !vm.isIos) return
  if (!vm.hasCompletedListItems) return
    return (
      <IonButton onClick={(e) => vm.showClearCompletedConfirm()}>
        <MaterialCommunityIcons name='broom' size={16} />
      </IonButton>
    )
  }

  const renderOpenMenu = () => {
    return (
      <IonButton onClick={(e) => vm.openMenu(e)}>
        <IonIcon icon={ellipsisVertical} mode='ios' />
      </IonButton>
    )
  }

  const renderExpandAll = () => {
    if (!vm.hasRows) return
    if (!vm.startCollapsed) return
    return (
      <IonButton onClick={(e) => vm.expandCollapseAll()}>
        <FontAwesomeIcon icon={faAngleDoubleDown} />
      </IonButton>
    )
  }

  const renderCollapseAll = () => {
    if (!vm.hasRows) return
    if (vm.startCollapsed) return
    return (
      <IonButton onClick={(e) => vm.expandCollapseAll()}>
        <FontAwesomeIcon icon={faAngleDoubleUp} />
      </IonButton>
    )
  }

  const renderPrintButton = () => {
    if (!vm.hasRows) return
    if (!vm.canPrint) return
    if (vm.pdfLoading) return
    return (
      <IonButton onClick={(e) => vm.pdf()}>
        <Feather name='printer' size={18} />
      </IonButton>
    )
  }

  const renderPrintButtonSpinner = () => {
    if (!vm.hasRows) return
    if (!vm.pdfLoading) return
    return <div id='spinner-container'>{renderActualSpinner()}</div>
  }

  const renderActualSpinner = () => {
    if (vm.isIos) return <IonSpinner />
    return <IonSpinner name='crescent' />
  }

  return (
    <div id='ListItemsListPageMoreMenu'>
      {renderSweepIcon()}
      {renderExpandAll()}
      {renderCollapseAll()}
      {renderPrintButton()}
      {renderPrintButtonSpinner()}
      {renderOpenMenu()}
      {renderClearCompletedConfirm()}

      <IonPopover
        id='ListItemsListPageMoreMenu_Content'
        isOpen={vm.menuShown}
        event={vm.event}
        onDidDismiss={() => vm.hideMenu()}
      >
        <IonItem lines='full' onClick={() => vm.clearCompleted()}>
          <MaterialCommunityIcons name='broom' size={18} />
          &nbsp; Clear Completed
        </IonItem>

        <IonItem lines='full' onClick={() => vm.sync()}>
          <IonIcon icon={refresh} size={'small'} />
          &nbsp; Sync
        </IonItem>

        <IonItem lines='full' onClick={() => vm.goToCategoriesSetup()}>
          <EvilIcons name='gear' size={18} />
          &nbsp; Categories
        </IonItem>
      </IonPopover>
    </div>
  )
}

export default inject('listItemsStore')(observer(ListItemsListPageMoreMenu))
