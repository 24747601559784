import { observable, makeObservable, computed, has } from 'mobx'
import { list, object, serializable, serialize } from 'serializr'
import { IStorageFileDTO } from '../../storage-files/dtos/IStorageFileDTO'
import { StorageFileFormat } from './StorageFileFormat'

export class StorageFile {
  constructor() {
    makeObservable(this)
  }

  @serializable @observable public StorageFileGuid: string
  @serializable @observable public FileName: string
  @serializable @observable public MimeType: string
  @serializable @observable public UniqueFileName: string
  @serializable @observable public Extension: string
  @serializable @observable public ProviderName: string
  @serializable @observable public Url: string
  @serializable @observable public AvailableOffline: boolean = false
  @serializable(list(object(StorageFileFormat))) @observable public Formats: StorageFileFormat[] = []

  @computed
  public get hasSignedUrl(): boolean {
    let hasSignature = this.Url.indexOf('sv=') >= 0
    if (!hasSignature) console.log(this.Url)
    return hasSignature
  }

  public toDTO(): IStorageFileDTO {
    return serialize(this)
  }
}
