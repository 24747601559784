import { observable, action, runInAction, makeObservable } from 'mobx'
import agent from '../../AgentV2'
import { RootStore } from '../../stores/RootStore'
import { makePersistable } from 'mobx-persist-store'
import { IAdaptiveCardTemplate } from '../interfaces/IAdaptiveCardTemplate'

export class AdaptiveCardTemplatesStore {
  private rootStore: RootStore

  constructor(rootStore) {
    makeObservable(this)
    makePersistable(this, { name: 'AdaptiveCardTemplatesStore', properties: ['templates'] }).then((st) => {
      let isHydrated = false
      if (process.env.NODE_ENV === 'test') isHydrated = true
      if (st && st.isHydrated) isHydrated = true
      if (isHydrated) this.onHydrationCompleted()
    })
    this.rootStore = rootStore
  }

  public onHydrationCompleted() {}

  @observable public templates: IAdaptiveCardTemplate[] = []

  @action
  public async loadData() {
    const allResponse = await agent.AdaptiveCardTemplates.getAll()
    if (!allResponse) return
    const moreTemplates = await Promise.all(
      allResponse
        .map((template) => {
          if (template.template) return undefined
          return agent.AdaptiveCardTemplates.get(template.id)
        })
        .filter((template) => template !== undefined)
    )
    moreTemplates.forEach((template) => {
      if (!template) return
      const item = allResponse.find((t) => t.id === template.id)
      if (!item) return
      item.template = template
    })
    runInAction(() => (this.templates = allResponse))
  }
}
