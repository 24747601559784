import { observable, action, computed, makeObservable } from 'mobx'
import { RootStore } from '../../stores/RootStore'
import { BotSession } from '../aggregate/BotSession'
import { BotSessionEditVM } from '../view-models/edit/BotSessionEditVM'
import { BotSessionsListVM } from '../view-models/list/BotSessionsListVM'
import { IBotSessionDTO } from '../dtos/IBotSessionDTO'
import { DataStore } from '@elexient/elexiapp.bits.shared'
import AgentV2 from '../../AgentV2'
import { makePersistable } from 'mobx-persist-store'
import { deserialize } from 'serializr'
import * as DataStoreServiceWorker from '../../shared/DataStoreService.worker'

export class BotSessionsStore extends DataStore<RootStore, BotSession, IBotSessionDTO> {
  constructor(rootStore: RootStore) {
    super(rootStore, BotSession, AgentV2, 'BotSessions', 'BotSession')
    super.setRecords(this.persistedRecords)
    makeObservable(this)
    makePersistable(this, { name: 'BotSessionsStore', properties: ['persistedRecords'] }).then(
      action((st) => this.onHydrationCompleted(st?.isHydrated))
    )
  }

  @action
  public onHydrationCompleted(isHydated?: boolean) {
    this.persistedRecords = observable.array(
      this.persistedRecords.map((e) => makeObservable(deserialize(BotSession, e)))
    )
    this.setRecords(this.persistedRecords)
    this.isHydrated = Boolean(isHydated)
    this.listVM = new BotSessionsListVM(this.rootStore)
    if (process.env.NODE_ENV === 'test') this.isHydrated = true
  }

  @observable public isHydrated: boolean = false
  @observable.shallow public persistedRecords: Array<BotSession> = []

  @observable public editVM: BotSessionEditVM = null
  @observable public listVM: BotSessionsListVM = null

  public async loadData() {
    await super.loadData()
  }

  @action
  public lazyLoadEditVM(botSessionGuid: string, attempts: number = 0) {
    if (attempts === 10) return
    if (botSessionGuid === 'empty') {
      this.editVM.dispose()
      this.editVM = null
      return
    }
    const found = this.get(botSessionGuid)
    if (!found) {
      setTimeout(() => this.lazyLoadEditVM(botSessionGuid, attempts++), 500)
      return
    }
    this.editVM = new BotSessionEditVM({
      rootStore: this.rootStore,
      botSession: found.clone(),
      description: found.Description,
      botName: found.Bot,
    })
  }

  @computed
  public get currentBoardRecords() {
    return this.persistedRecords.filter(
      (e) => e.BoardId === this.rootStore.boardsStore.currentBoardId || e.BoardId === 0
    )
  }

  protected getWorker() {
    return (DataStoreServiceWorker as any)() as typeof DataStoreServiceWorker
  }
}
