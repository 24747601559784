import React from 'react'
import { Observer, inject, observer, useLocalObservable } from 'mobx-react'
import StorageFileImage from './StorageFileImage'
import { StorageFile } from '../aggregate/StorageFile'
import { SortableContainer, SortableElement } from 'react-sortable-hoc'
import { RootStore } from '../../stores/RootStore'
import { ImagesGridVM } from '../view-models/ImagesGridVM'
import LongPress from '../../utils/LongPress'

interface Props {
  rootStore?: RootStore
  storageFiles: StorageFile[]
  onImageClick: (index: number) => void
  onSortEnd?: (oldIndex: number, newIndex: number) => void
  sortable: boolean
  columns?: number
}

const ImagesGrid: React.FC<Props> = ({ rootStore, storageFiles, onImageClick, onSortEnd, columns, sortable }) => {
  const vm = useLocalObservable(() => new ImagesGridVM(rootStore, sortable))

  const renderRow = (sf: StorageFile, idx: number) => {
    return (
      <div className='grid-item' key={sf.StorageFileGuid} onClick={() => onImageClick(idx)}>
        <StorageFileImage storageFile={sf} />
      </div>
    )
  }

  const SortableItem = SortableElement((props) => {
    return <div>{renderRow(props.value, props.sortIndex)}</div>
  })

  const SortableList = SortableContainer(({ items }) => {
    return (
      <div>
        {items.map((value, idx) => {
          return (
            <SortableItem
              key={`item-${value.StorageFileGuid}${vm.sortMode}`}
              disabled={!vm.sortMode}
              index={idx}
              sortIndex={idx}
              value={value}
            />
          )
        })}
      </div>
    )
  })

  const renderSortableList = () => {
    return (
      <SortableList
        distance={1}
        items={storageFiles}
        onSortEnd={({ oldIndex, newIndex }) => {
          onSortEnd(oldIndex, newIndex)
          // vm.endSort()
        }}
      />
    )
  }

  return (
    <LongPress onLongPress={() => vm.startSort()}>
      <div id='ImagesGrid' className={!columns ? 'auto' : 'colcnt-' + columns} key={String(vm.sortMode)}>
        <div className='grid-container'>{renderSortableList()}</div>
      </div>
    </LongPress>
  )
}

export default inject('rootStore')(observer(ImagesGrid))
