import React, { useEffect } from 'react'
import { inject, observer, useLocalObservable } from 'mobx-react'
import { IonImg, IonSkeletonText } from '@ionic/react'
import { RootStore } from '../../stores/RootStore'
import { StorageFile } from '../aggregate/StorageFile'
import { StorageFileImageVM } from '../view-models/StorageFileImageVM'

interface Props {
  rootStore?: RootStore
  storageFile: StorageFile
  height?: number
}

const StorageFileImage: React.FC<Props> = ({ rootStore, storageFile, height }) => {
  const vm = useLocalObservable(() => new StorageFileImageVM(rootStore, storageFile))

  useEffect(() => {
    return () => vm.dispose()
  }, [])

  const renderImg = () => {
    if (vm.isLoading) return
    return <img src={vm.url} height={height} />
  }

  const renderSpinner = () => {
    if (!vm.isLoading) return
    return (
      <div className='storage-file-image'>
        <IonSkeletonText animated={true}></IonSkeletonText>
      </div>
    )
  }

  return (
    <>
      {renderSpinner()}
      {renderImg()}
    </>
  )
}

export default inject('rootStore')(observer(StorageFileImage))
