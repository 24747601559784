import { makeObservable, computed, reaction, IReactionDisposer, action, observable } from 'mobx'
import { RootStore } from 'src/stores/RootStore'
import { BotSessionsService } from '../../services/BotSessionsService'
import { BotSession } from '../../aggregate/BotSession'

export class BotSessionNewVM {
  private reactionDisposers: IReactionDisposer[]
  @observable private botSession: BotSession = undefined
  private bot: string

  constructor(private rootStore: RootStore, bot: string) {
    makeObservable(this)
    if (!bot) {
      this.rootStore.appStore.navigateTo('/botsessionslist')
      return
    }
    this.bot = bot
    this.createBotSession(bot)
    this.reactionDisposers = [
      reaction(
        () => this.rootStore.botSessionsStore.get(this.botSession.BotSessionGuid),
        (botSession) => this.setBotSession(botSession.clone())
      ),
    ]
  }

  private getBotTitle(): string {
    switch (this.bot) {
      case 'recipe-from-photo-v1':
        return 'Generate recipe from photo'
      case 'recipe-from-url-v1':
        return 'Import recipe from URL'
      case 'recipe-wizard-v1':
        return 'Create recipe'
      case 'recipe-from-pantry-v1':
        return 'Recipe from pantry'
      default:
        return 'Bot Session'
    }
  }

  @action
  private createBotSession(bot: string) {
    this.botSession = BotSession.create(this.rootStore.boardsStore.currentBoardId, bot, this.getBotTitle())
    const svc = new BotSessionsService(this.rootStore)
    svc.save(this.botSession.toDTO())
  }

  @action
  private setBotSession(botSession: BotSession) {
    this.botSession = botSession
    let shouldRedirect = true
    if (this.botSession.Interactions.length === 0) shouldRedirect = true
    if (this.botSession.Bot === 'recipe-from-photo-v1') shouldRedirect = true
    if (!shouldRedirect) return
    this.reactionDisposers.forEach((e) => e())
    const url = `/botsessionedit/${this.botSession.BotSessionGuid}`
    this.rootStore.appStore.navigateTo(url, true)
  }

  private get messageOptions(): string[] {
    return [
      "Waiting for the AI to wake up and grant me its wisdom...hope it's had its coffee!",
      "Just giving the AI a moment to put on its thinking cap. Can't rush brilliance!",
      "Waiting for the AI to catch up. It's not easy being this clever!",
      'Patiently waiting while the AI flexes its digital muscles. Genius takes time!',
      "Giving the AI a moment to assemble all its brainpower. It's worth the wait!",
      'Letting the AI brew up some genius thoughts. Good things take time!',
      "Waiting for the AI to load up on inspiration. Can't rush a masterpiece!",
      'Giving the AI a second to align its pixels. Genius in progress!',
      'Waiting for the AI to synchronize with the universe. Smart thoughts incoming!',
      "Allowing the AI a moment to gather its thoughts. It's cooking up something good!",
      'Giving the AI a breather to compile all the cleverness. Almost there!',
      'Waiting for the AI to connect all the dots. Smart ideas loading!',
    ]
  }

  @computed
  public get message(): string {
    return this.messageOptions[Math.floor(Math.random() * this.messageOptions.length)]
  }
}
