import { observable, action, runInAction, computed, makeObservable } from 'mobx'
import { persist } from 'mobx-persist'
import { Item } from '../aggregate/Item'
import { RootStore } from '../../stores/RootStore'
import { ItemEditVM } from '../view-models/edit/ItemEditVM'
import { IItemDTO } from '../dtos/IItemDTO'
import { IItemsStore } from './IItemsStore'
import { DataStore } from '@elexient/elexiapp.bits.shared'
import AgentV2 from '../../AgentV2'
import { makePersistable } from 'mobx-persist-store'
import { deserialize } from 'serializr'
import { ItemsListVM } from '../view-models/list/ItemsListVM'
import * as DataStoreServiceWorker from '../../shared/DataStoreService.worker'

export class ItemsStore extends DataStore<RootStore, Item, IItemDTO> implements IItemsStore {
  constructor(rootStore: RootStore) {
    super(rootStore, Item, AgentV2, 'Items', 'Item')
    super.setRecords(this.persistedRecords)
    makeObservable(this)
    makePersistable(this, { name: 'ItemsStore', properties: ['persistedRecords'] }).then(
      action((st) => this.onHydrationCompleted(st?.isHydrated))
    )
  }

  @action
  public onHydrationCompleted(isHydated?: boolean) {
    this.persistedRecords = observable.array(this.persistedRecords.map((e) => makeObservable(deserialize(Item, e))))
    this.setRecords(this.persistedRecords)
    this.isHydrated = Boolean(isHydated)
    if (process.env.NODE_ENV === 'test') this.isHydrated = true
    this.lazyLoadListVM()
  }

  @action
  private lazyLoadListVM() {
    if (!this.rootStore.categoriesStore.isHydrated) {
      setTimeout(() => this.lazyLoadListVM(), 100)
      return
    }
    if (!this.rootStore.listItemsStore.isHydrated) {
      setTimeout(() => this.lazyLoadListVM(), 100)
      return
    }
    this.listVM = new ItemsListVM(this.rootStore)
  }

  @observable public editVM: ItemEditVM = null
  @observable public listVM: ItemsListVM = null
  @observable.shallow public persistedRecords: Array<Item> = []
  @observable public isHydrated: boolean = false

  @action
  public lazyLoadEditVM(itemGuid: string, attempts: number = 0) {
    if (attempts === 10) return
    if (!this.rootStore.itemsStore.isHydrated) {
      setTimeout(() => this.lazyLoadEditVM(itemGuid, attempts++), 50)
      return
    }
    if (!this.rootStore.listItemsStore.isHydrated) {
      setTimeout(() => this.lazyLoadEditVM(itemGuid, attempts++), 50)
      return
    }
    if (!this.rootStore.categoriesStore.isHydrated) {
      setTimeout(() => this.lazyLoadEditVM(itemGuid, attempts++), 50)
      return
    }
    if (!this.rootStore.storageFilesStore.isHydrated) {
      setTimeout(() => this.lazyLoadEditVM(itemGuid, attempts++), 50)
      return
    }

    if (itemGuid === 'new') {
      this.editVM = new ItemEditVM(this.rootStore, Item.create(this.rootStore.boardsStore.currentBoardId), true)
      return
    }
    const foundItem = this.get(itemGuid)
    if (!foundItem) {
      setTimeout(() => this.lazyLoadEditVM(itemGuid, attempts++), 500)
      return
    }
    this.editVM = new ItemEditVM(this.rootStore, foundItem.clone())
  }

  @computed
  public get currentBoardRecords(): Array<Item> {
    return this.persistedRecords.filter((e) => e.BoardId === this.rootStore.boardsStore.currentBoardId)
  }

  protected getWorker() {
    return (DataStoreServiceWorker as any)() as typeof DataStoreServiceWorker
  }
}
