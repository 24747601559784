import { observable, action, computed, makeObservable } from 'mobx'
import { IInteractionDTO } from '../dtos/IInteractionDTO'
import { deserialize, object, serializable, serialize } from 'serializr'
import { StorageFile } from './StorageFile'
import generateUUID from '../../utils/UUID'
import { IStorageFileDTO } from '../../storage-files/dtos/IStorageFileDTO'

export class Interaction implements IInteractionDTO {
  public static createPendingInteraction(rank: number) {
    const interaction = new Interaction()
    interaction.InteractionGuid = generateUUID()
    interaction.Rank = rank
    interaction.IsProcessing = true
    interaction.CreatedDateTime = new Date().toISOString()
    return interaction
  }

  public static createPendingImageInteraction(rank: number) {
    const interaction = new Interaction()
    interaction.InteractionGuid = generateUUID()
    interaction.Rank = rank
    interaction.IsProcessing = true
    interaction.AdaptiveCardTemplateName = 'generated-image-v1'
    interaction.CreatedDateTime = new Date().toISOString()
    return interaction
  }

  public static createUploadImageInteraction(rank: number) {
    const interaction = new Interaction()
    interaction.InteractionGuid = generateUUID()
    interaction.Rank = rank
    interaction.IsProcessing = false
    interaction.AdaptiveCardTemplateName = 'recipe-from-image-form-v1'
    interaction.CreatedDateTime = new Date().toISOString()
    return interaction
  }

  public static createStorageFileInteraction(storageFileGuid: string, rank: number) {
    const interaction = new Interaction()
    interaction.InteractionGuid = generateUUID()
    interaction.StorageFileGuid = storageFileGuid
    interaction.Rank = rank
    interaction.IsProcessing = true
    interaction.CreatedDateTime = new Date().toISOString()
    return interaction
  }

  constructor() {
    makeObservable(this)
  }

  @serializable @observable public InteractionGuid: string = ''
  @serializable @observable public Question: string = ''
  @serializable @observable public Answer: string = ''
  @serializable @observable public AdaptiveCardJson: string = ''
  @serializable @observable public AdaptiveCardAnswerJson: string = ''
  @serializable @observable public AdaptiveCardTemplateName: string = ''
  @serializable @observable public DetailsJson: string = ''
  @serializable @observable public StorageFileGuid: string = undefined
  @serializable @observable public Rank: number = 0
  @serializable @observable public IsProcessing: boolean = false
  @serializable @observable public CreatedDateTime: string = ''
  @serializable(object(StorageFile)) @observable public StorageFile: StorageFile = undefined
  IsDeleted: boolean = false

  @computed
  public get createdDateTimeAsDate() {
    return new Date(this.CreatedDateTime)
  }

  @action
  public setAnswer(adaptiveCardAnswer: any) {
    let answer = Object.values(adaptiveCardAnswer)[0].toString()
    if (answer === 'Other') answer = Object.values(adaptiveCardAnswer)[1].toString()
    this.Answer = answer
    this.AdaptiveCardAnswerJson = JSON.stringify(adaptiveCardAnswer)
  }

  @computed
  public get details() {
    if (!this.DetailsJson) return {}
    if (this.DetailsJson === '') return {}
    return JSON.parse(this.DetailsJson)
  }

  @action
  public setDetails(key: string, value: any) {
    let currentJson = this.DetailsJson
    if (!currentJson) currentJson = '{}'
    if (currentJson === '') currentJson = '{}'
    const details = JSON.parse(currentJson)
    details[key] = value
    this.DetailsJson = JSON.stringify(details)
  }

  @action
  public markAsNotAnswered() {
    this.setDetails('Answered', false)
  }

  @action
  public setStorageFile(dto: IStorageFileDTO) {
    this.StorageFileGuid = dto.StorageFileGuid
    this.StorageFile = deserialize(StorageFile, dto)
  }

  @action
  public markAsProcessing() {
    this.IsProcessing = true
  }

  @action
  public markAsNotProcessing() {
    this.IsProcessing = false
  }

  @action
  public markAsDeleted() {
    this.IsDeleted = true
  }

  public toDTO() {
    let dto = serialize(this)
    dto.details = this.details
    return dto
  }
}
