import React from 'react'
import OfflineToast from '../../../shared/OfflineToast'
import ListItemsList from './ListItemsList'
import ListItemsListPTR from './ListItemsListPTR'
import { AppStore } from '../../../stores/AppStore'
import { ListItemsStore } from '../../store/ListItemsStore'
import { inject, observer } from 'mobx-react'
import { IonButton, IonIcon } from '@ionic/react'
import { add } from 'ionicons/icons'
import BotSessionEditModal from '../../../bot-sessions/views/edit/BotSessionEditModal'
import SpinnerV2 from '../../../shared/spinner-v2/SpinnerV2'

interface Props {
  appStore?: AppStore
  listItemsStore?: ListItemsStore
}

const ListItemsListPageContent: React.FC<Props> = ({ listItemsStore, appStore }) => {
  const { listVM } = listItemsStore

  const renderNoItemsMessage = () => {
    if (listVM.allRows.length > 2) return null
    return (
      <div className='no-items'>
        <br />
        <br />
        Your shopping list is empty.
        <br />
        <br />
        <IonButton slot='icon-only' type='button' color='tertiary' onClick={() => listVM.goToItemsSelect()}>
          <IonIcon icon={add} />
        </IonButton>
      </div>
    )
  }

  const renderListeningIndicator = () => {
    return <SpinnerV2 type='listening' isVisible={listVM.isCapturingAudio} />
  }

  return (
    <>
      <OfflineToast shown={listVM.offlineToastShown} onDidDismiss={() => listVM.hideOfflineToast()} />
      <ListItemsListPTR />
      {renderNoItemsMessage()}
      {renderListeningIndicator()}
      <BotSessionEditModal
        botName='capture-list-items-voice-v1'
        shown={listVM.captureVoiceModalShown}
        onHide={() => listVM.hideCaptureVoiceModal()}
        title='Add items by voice'
        description='capture list items from voice'
        parentRecordTable='listitems.ListItem'
      />
      <ListItemsList />
    </>
  )
}

export default inject('appStore', 'listItemsStore')(observer(ListItemsListPageContent))
