import React from 'react'
// import { FixedSizeList as List, ListChildComponentProps } from 'react-window'
// import { List, AutoSizer, CellMeasurer, CellMeasurerCache, ListRowRenderer } from 'react-virtualized'
// import { useWindowSize } from '../../../shared/useWindowSize'
import { inject, observer } from 'mobx-react'
import { RecipesStore } from '../../store/RecipesStore'
import { AppStore } from '../../../stores/AppStore'
import { RecipeRowVM } from '../../view-models/list/RecipeRowVM'
import { RecipeGroupRowVM } from '../../view-models/list/RecipeGroupRowVM'
import StorageFileImage from '../../../storage-files/views/StorageFileImage'
// import { IonText } from '@ionic/react'
import RecipePlaceholderImg from '../../../assets/images/recipe-placeholder.png'

interface Props {
  appStore?: AppStore
  recipesStore?: RecipesStore
}

const RecipeCategoriesList: React.FC<Props> = ({ recipesStore, appStore }) => {
  const { listVM } = recipesStore
  // const { width, height } = useWindowSize()
  const columns = undefined

  // const cache = new CellMeasurerCache({
  //   defaultHeight: 200,
  //   fixedWidth: true,
  // })

  const renderImagesGrid = (group: RecipeGroupRowVM) => {
    return (
      <div id='ImagesGridV2' className={!columns ? 'auto' : 'colcnt-' + columns} key={String(group.name)}>
        <div className='grid-container'>
          {listVM.allRows
            .filter((e) => e.rowType === 'normal' && e.parentGroup1.name === group.name)
            .map((row: RecipeRowVM) => (
              <div className='grid-item' key={group.name + '_' + row.recipeGuid} onClick={() => row.click()}>
                <div className='img'>
                  {!row.storageFile ? (
                    <img src={RecipePlaceholderImg} className='placeholder' />
                  ) : (
                    <StorageFileImage storageFile={row.storageFile} />
                  )}
                </div>
                <div className='title'>{row.name}</div>
              </div>
            ))}
          <div className='grid-item blank'></div>
          <div className='grid-item blank'></div>
          <div className='grid-item blank'></div>
          <div className='grid-item blank'></div>
          <div className='grid-item blank'></div>
          <div className='grid-item blank'></div>
          <div className='grid-item blank'></div>
          <div className='grid-item blank'></div>
          <div className='grid-item blank'></div>
        </div>
      </div>
    )
  }

  // const renderCategories = () => {
  return (
    <>
      {listVM.group1Rows.map((group) => (
        <div key={group.name}>
          <div className='group-header'>{group.displayName}</div>
          {renderImagesGrid(group as RecipeGroupRowVM)}
        </div>
      ))}
    </>
  )
  // }

  // const rowRenderer: ListRowRenderer = ({ index, key, parent, style }) => {
  //   const category = categories[index]
  //   return (
  //     // <CellMeasurer key={key} cache={cache} parent={parent} columnIndex={0} rowIndex={index}>
  //       <div style={style}>
  //         <div className='group-header'>{category.displayName}</div>
  //         {renderImagesGrid(category as RecipeGroupRowVM)}
  //       </div>
  //     // </CellMeasurer>
  //   )
  // }

  // return <div>hi</div>
  // return renderCategories()

  // <AutoSizer>
  //   {({ height, width }) => (
  // <List
  //   width={width}
  //   height={height}
  //   rowHeight={cache.rowHeight}
  //   deferredMeasurementCache={cache}
  //   rowCount={categories.length}
  //   rowRenderer={rowRenderer}
  // />
  //   )}
  // </AutoSizer>
}

export default inject('recipesStore', 'appStore')(observer(RecipeCategoriesList))
