import { observable, action, makeObservable } from 'mobx'
import { serializable } from 'serializr'
import { IAttachmentDTO } from '../dtos/IAttachmentDTO'

export class Attachment implements IAttachmentDTO {
  constructor() {
    makeObservable(this)
  }

  @serializable @observable public AttachmentGuid: string = undefined
  @serializable @observable public StorageFileGuid: string = undefined
  @serializable @observable public Rank: number = undefined
  @serializable @observable public IsDeleted: boolean = false
}
