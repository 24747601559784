import React from 'react'
import { IonContent, IonItem, IonIcon, IonButton, IonHeader, IonToolbar, IonTitle, IonButtons } from '@ionic/react'
import { observer, inject } from 'mobx-react'
import { AppStore } from 'src/stores/AppStore'
import { trash, add, arrowBack, trashOutline } from 'ionicons/icons'
import { RootStore } from 'src/stores/RootStore'
import BotSessionsList from './BotSessionsList'
import BackIcon from 'src/shared/BackIcon'
import { BotSessionsStore } from '../../store/BotSessionsStore'

interface Props {
  botSessionsStore: BotSessionsStore
  appStore: AppStore
  rootStore: RootStore
  history: any
}

interface State {
  isNewModalOpen: boolean
  isEditModalOpen: boolean
  editBotSessionGuid: string
  isDeleteConfirmShown: boolean
  deleteBotSessionGuid: string
  deleteBotSessionName: string
}

@inject('botSessionsStore')
@inject('appStore')
@observer
export default class BotSessionsListPage extends React.Component<Props, State> {
  constructor(props) {
    super(props)
  }

  componentDidMount() {
    this.props.appStore.setCurrentRoute('/botSessionslist')
  }

  handleAddButtonClick() {
    this.setState({ isNewModalOpen: true })
  }

  handleEditClick(botSessionGuid) {
    this.setState({
      isEditModalOpen: true,
      editBotSessionGuid: botSessionGuid,
    })
  }

  handleGoBack() {
    this.props.appStore.handleGoBack(this.props.history)
  }

  openListItem(listItem) {
    this.props.history.push('/maintabs/listitemedit/' + listItem.ListItemGuid)
  }

  render() {
    return (
      <>
        {this.props.appStore.showAppHeader && (
          <IonHeader>
            <IonToolbar />
          </IonHeader>
        )}

        <IonHeader id='BotSessionsListPage_Header'>
          <IonToolbar color='secondary'>
            <IonButtons slot='start'>
              <IonButton onClick={() => this.handleGoBack()}>
                <BackIcon />
              </IonButton>
            </IonButtons>
            <IonTitle>Generate Recipe</IonTitle>
          </IonToolbar>
        </IonHeader>

        <IonContent
          id='BotSessionsListPage_Content'
          scrollY
          ref={(e) => this.props.appStore.applyScrollStyles(e)}
          style={{ height: this.props.appStore.listHeightPx }}
        >
          <BotSessionsList />
        </IonContent>
      </>
    )
  }
}
