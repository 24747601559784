import React, { useState } from 'react'
import { inject, observer } from 'mobx-react'
import VirtualList from 'react-tiny-virtual-list'
import { AppStore } from 'src/stores/AppStore'
import BotSessionRow from './BotSessionRow'
import { BotSessionsStore } from '../../store/BotSessionsStore'
import {
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonCol,
  IonGrid,
  IonHeader,
  IonIcon,
  IonList,
  IonRippleEffect,
  IonRow,
  IonText,
  IonTitle,
} from '@ionic/react'
import { arrowForwardOutline, handRightSharp } from 'ionicons/icons'
import { Feather, MaterialCommunityIcons } from 'react-web-vector-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLink, faMagic, faUpload } from '@fortawesome/free-solid-svg-icons'

interface Props {
  botSessionsStore?: BotSessionsStore
  appStore?: AppStore
}

const BotSessionsList: React.FC<Props> = ({ botSessionsStore, appStore }) => {
  const listVM = botSessionsStore.listVM
  if (!listVM) return null
  if (appStore.bodyHeight === 0) return null

  const renderListRows = () => {
    return listVM.rows.map((e, idx) => <BotSessionRow key={'botsession_' + e.botSessionGuid} row={e} />)
  }

  const renderBotOptions = () => {
    return listVM.botOptions.map((e) => {
      return (
        <IonCol sizeXs={e.xsWidth.toString()} sizeMd='3' key={e.value}>
          <IonCard onClick={() => listVM.startSession(e.value)} className='ion-activatable'>
            <IonRippleEffect type='unbounded' />
            <IonCardHeader>
              <IonCardTitle>
                {/* {e.title === 'Import' && <Feather name='link' size={18} />} */}
                {e.title === 'Import' && <FontAwesomeIcon icon={faLink} />}
                {e.title === 'Create' && <FontAwesomeIcon icon={faMagic} />}
                {e.title === 'Upload' && <Feather name='camera' />}
                {e.title === 'Pantry' && <Feather name='home' />}
                {e.title}
              </IonCardTitle>
            </IonCardHeader>
            <IonCardContent>{e.subTitle}</IonCardContent>
            <div className='actions'>
              <IonButton fill='clear'>
                <IonIcon icon={arrowForwardOutline} color='tertiary' />
              </IonButton>
            </div>
          </IonCard>
        </IonCol>
      )
    })
  }

  return (
    <>
      <IonGrid id='BotOptions'>
        <IonRow>{renderBotOptions()}</IonRow>
      </IonGrid>
      <IonText id='past'>Recent</IonText>
      <IonList>{renderListRows()}</IonList>
    </>
  )
}

export default inject('botSessionsStore', 'appStore')(observer(BotSessionsList))
