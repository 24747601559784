import React from 'react'
import { IonButton, IonIcon } from '@ionic/react'
import { inject, observer } from 'mobx-react'
import { UserStore } from '../store/UserStore'
import UserAvatar from 'react-user-avatar'
import AvatarColors from '../../theme/AvatarColors'
import { AppStore } from '../../stores/AppStore'
import '../styles/AccountMenu.scss'
import { AntDesign, MaterialCommunityIcons } from 'react-web-vector-icons'
import { notificationsOutline } from 'ionicons/icons'

interface Props {
  userStore?: UserStore
  appStore?: AppStore
}

const AccountMenu: React.FC<Props> = ({ userStore, appStore }) => {
  const { user } = userStore
  const { hasAvailableAppUpdate, isSeeding } = appStore
  if (!user) return null
  if (!user.Name) return null

  const renderUpdateIcon = () => {
    if (!isSeeding && !hasAvailableAppUpdate) return
    return (
      <IonButton onClick={() => appStore.navigateTo('/settings')}>
        <IonIcon icon={notificationsOutline} />
        <MaterialCommunityIcons name='numeric-1-box' size={16} />
      </IonButton>
    )
  }

  return (
    <div id='AccountMenu'>
      {renderUpdateIcon()}
      <IonButton onClick={() => appStore.navigateTo('/settings')}>
        <UserAvatar size='36' colors={AvatarColors} name={user.Name} />
      </IonButton>
    </div>
  )
}

export default inject('userStore', 'appStore')(observer(AccountMenu))
