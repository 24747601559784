import { observable, action, makeObservable } from 'mobx'
import generateUUID from '../../utils/UUID'
import { IStepDTO } from '../dto/IStepDTO'
import { serializable } from 'serializr'

export class Step implements IStepDTO {
  public static create(stepGuid: string = null) {
    const st = new Step()
    st.StepGuid = stepGuid
    if (!stepGuid) st.StepGuid = generateUUID()
    return st
  }

  constructor() {
    makeObservable(this)
  }

  @serializable @observable public StepGuid: string = ''
  @serializable @observable public StepNumber: number = 0
  @serializable @observable public Description: string = ''
  @serializable @observable public IsDeleted: boolean = false

  @action
  public setDescription(val) {
    this.Description = val
  }

  @action
  public setStepNumber(val: number) {
    this.StepNumber = val
  }

  @action
  public updateFromServer(serverStep: IStepDTO) {
    this.StepNumber = serverStep.StepNumber
    this.Description = serverStep.Description
  }

  @action
  public markAsDeleted() {
    this.IsDeleted = true
  }
}
