import { observable, action, computed, makeObservable } from 'mobx'
import { RootStore } from '../../../stores/RootStore'
import { RecipeEditVM } from './RecipeEditVM'
import { RecipeCategory } from '../../../recipe-categories/aggregate/RecipeCategory'
import { RecipeCategoriesService } from '../../../recipe-categories/services/RecipeCategoriesService'

export class RecipeCategoryNewVM {
  private rootStore: RootStore
  private editVM: RecipeEditVM
  @observable private recipeCategory: RecipeCategory = null
  private inputRef: HTMLIonInputElement
  private focused: boolean = false

  constructor(rootStore: RootStore, editVM: RecipeEditVM) {
    makeObservable(this)
    this.rootStore = rootStore
    this.editVM = editVM
  }

  @observable public shown: boolean = false

  @computed
  public get name(): string {
    if (!this.recipeCategory) return ''
    return this.recipeCategory.Name
  }

  @action
  public setName(val: string) {
    this.recipeCategory.setName(val)
  }

  @computed
  public get isValid(): boolean {
    return this.name !== ''
  }

  @action
  public async save() {
    const svc = new RecipeCategoriesService(this.rootStore)
    await svc.save(this.recipeCategory.toDTO())
    this.toggleShown()
    this.editVM.recipe.addCategory(this.recipeCategory.RecipeCategoryGuid)
  }

  @action
  public cancel() {
    this.hide()
  }

  @action
  public toggleShown() {
    this.shown = !this.shown
    if (this.shown) {
      this.recipeCategory = RecipeCategory.create(this.rootStore.boardsStore.currentBoardId)
      this.focused = false
    }
  }

  @action
  public hide() {
    this.shown = false
  }

  @action
  public setInputRef(e: HTMLIonInputElement) {
    this.inputRef = e
    if (this.focused) return
    setTimeout(() => e.setFocus(), 1000)
    this.focused = true
    e.setAttribute('autocomplete', '9something-new' + Math.random())
  }
}
