import React from 'react'
import { IonContent, IonButton, IonHeader, IonToolbar, IonTitle, IonButtons, IonText, IonSpinner } from '@ionic/react'
import { observer, inject } from 'mobx-react'
import { AppStore } from '../../../stores/AppStore'
import { RootStore } from '../../../stores/RootStore'
import { match } from 'react-router'
import BackIcon from 'src/shared/BackIcon'
import { BotSessionNewVM } from '../../view-models/new/BotSessionNewVM'

interface Props {
  appStore: AppStore
  rootStore: RootStore
  history: any
  match: match
}

@inject('rootStore')
@inject('appStore')
@observer
export default class BotSessionNewPage extends React.Component<Props> {
  private vm: BotSessionNewVM

  constructor(props) {
    super(props)
    this.vm = new BotSessionNewVM(this.props.rootStore, this.props.match.params['id'])
  }

  componentDidMount() {
    this.props.appStore.setCurrentRoute('/botsessionnew/' + this.props.match.params['id'])
  }

  handleGoBack() {
    this.props.appStore.handleGoBack(this.props.history)
  }

  render() {
    if (!this.vm) return null
    return (
      <>
        {this.props.appStore.showAppHeader && (
          <IonHeader>
            <IonToolbar />
          </IonHeader>
        )}

        <IonHeader id='BotSessionNewPage_Header'>
          <IonToolbar color='secondary'>
            <IonButtons slot='start'>
              <IonButton onClick={() => this.handleGoBack()}>
                <BackIcon />
              </IonButton>
            </IonButtons>
            <IonTitle>Session starting...</IonTitle>
          </IonToolbar>
        </IonHeader>

        <IonContent
          id='BotSessionNewPage_Content'
          ref={(e) => this.props.appStore.applyScrollStyles(e)}
          style={{ height: this.props.appStore.listHeightPx }}
          scrollY
        >
          <div id='SpinnerContainer'>
            <IonSpinner name='crescent' />
          </div>
          <IonText>{this.vm.message}</IonText>
        </IonContent>
      </>
    )
  }
}
