import * as AdaptiveCards from 'adaptivecards'
import * as ACData from 'adaptivecards-templating'
import { Converter } from 'showdown'
import hostConfigLightDef from '../config/hostConfig.light'
import hostConfigDarkDef from '../config/hostConfig.dark'
import { RootStore } from '../../stores/RootStore'

export class AdaptiveCardVM {
  private adaptiveCard: AdaptiveCards.AdaptiveCard

  constructor(
    private rootStore: RootStore,
    private card: any,
    private onSubmit: (card, actionData) => void,
    private data: any
  ) {
    this.adaptiveCard = new AdaptiveCards.AdaptiveCard()
  }

  public handleSubmit(actionData: any) {
    if (!actionData) return
    this.onSubmit(this.adaptiveCard, actionData)
  }

  public submitCard() {
    const submitAction = this.adaptiveCard
      .getAllActions()
      .find((action) => action instanceof AdaptiveCards.SubmitAction)
    if (!submitAction) return
    submitAction.execute()
  }

  public setCard(data) {
    this.card = data
  }

  public setData(data) {
    this.data = data
  }

  public getRenderedCard() {
    const theme = window.rootStore.appStore.themeGenVM.actualTheme
    const hostConfig = theme === 'dark' ? hostConfigDarkDef : hostConfigLightDef
    this.adaptiveCard.hostConfig = new AdaptiveCards.HostConfig(hostConfig)

    this.adaptiveCard.onExecuteAction = (action) => {
      if (action instanceof AdaptiveCards.SubmitAction) {
        this.handleSubmit(action.data)
      }
      if (action instanceof AdaptiveCards.ExecuteAction) {
        this.handleSubmit(action.data)
      }
    }

    this.adaptiveCard.onInputValueChanged = (input) => {
      if (input instanceof AdaptiveCards.ChoiceSetInput) {
        const otherTextBox = this.adaptiveCard.getAllInputs().find((input) => {
          if (input instanceof AdaptiveCards.TextInput) return true
        })
        const nextActionSet = this.adaptiveCard.getElementById('NextActionSet')
        if (input.value === 'Other' && otherTextBox) {
          nextActionSet.isVisible = true
          otherTextBox.isVisible = true
          otherTextBox.focus()
          return
        }
        if (otherTextBox) otherTextBox.isVisible = false
        this.handleSubmit(undefined)
      }
    }

    AdaptiveCards.AdaptiveCard.onProcessMarkdown = (text, result) => {
      const converter = new Converter()
      const html = converter.makeHtml(text)
      result.outputHtml = html
      result.didProcess = true
    }

    // if (this.answer) {
    //   this.card.body.forEach((element) => {
    //     if (element.id && this.answer[element.id] !== undefined) {
    //       element.value = this.answer[element.id]
    //     }
    //     if (element.type === 'Input.Text' && this.answer['other']) {
    //       element.isVisible = true
    //     }
    //   })
    // }

    // if (this.recipeGuid) {
    //   this.card.actions.push({
    //     type: 'Action.Execute',
    //     title: 'Open Recipe',
    //     id: 'openRecipe',
    //   })
    // }

    if (this.data) {
      var template = new ACData.Template(this.card)
      this.card = template.expand({
        $root: {
          ...this.data,
        },
      })
    }

    if (!this.card) {
      console.log('no card')
      return document.createElement('div')
    }

    this.adaptiveCard.parse(this.card)

    // const saveRecipeBtn = this.adaptiveCard.getActionById('saveRecipe')
    // if (saveRecipeBtn) {
    //   saveRecipeBtn.onExecute = (action) => this.onSaveRecipe()
    // }

    // const openRecipeBtn = this.adaptiveCard.getActionById('openRecipe')
    // if (openRecipeBtn) {
    //   openRecipeBtn.onExecute = (action) => this.openRecipe()
    // }

    const renderedCard = this.adaptiveCard.render()
    return renderedCard
  }

  // private openRecipe() {
  //   this.rootStore.appStore.navigateTo('/recipeedit/' + this.recipeGuid)
  // }
}
