import { observable, action, computed, makeObservable } from 'mobx'
import { persist } from 'mobx-persist'
import { RootStore } from '../../stores/RootStore'
import { RecipeEditVM } from '../view-models/edit/RecipeEditVM'
import { Recipe } from '../aggregate/Recipe'
import { RecipesListVM } from '../view-models/list/RecipesListVM'
import { IRecipeDTO } from '../dto/IRecipeDTO'
import AgentV2 from '../../AgentV2'
import { makePersistable } from 'mobx-persist-store'
import { deserialize } from 'serializr'
import { DataStore } from '@elexient/elexiapp.bits.shared'
import * as DataStoreServiceWorker from '../../shared/DataStoreService.worker'

export class RecipesStore extends DataStore<RootStore, Recipe, IRecipeDTO> {
  constructor(rootStore: RootStore) {
    super(rootStore, Recipe, AgentV2, 'Recipes', 'Recipe')
    super.setRecords(this.persistedRecords)
    makeObservable(this)
    makePersistable(this, { name: 'RecipesStore', properties: ['persistedRecords'] }).then(
      action((st) => this.onHydrationCompleted(st?.isHydrated))
    )
  }

  @action
  public onHydrationCompleted(isHydated?: boolean) {
    this.persistedRecords = observable.array(this.persistedRecords.map((e) => makeObservable(deserialize(Recipe, e))))
    this.setRecords(this.persistedRecords)
    this.isHydrated = Boolean(isHydated)
    if (process.env.NODE_ENV === 'test') this.isHydrated = true
    this.loadRecipesListVM()
  }

  private loadRecipesListVM() {
    if (!this.rootStore.recipeCategoriesStore.isHydrated) {
      setTimeout(() => this.loadRecipesListVM(), 200)
      return
    }
    this.listVM = new RecipesListVM(this.rootStore)
  }

  @observable public editVM: RecipeEditVM = null
  @observable public listVM: RecipesListVM = null
  @observable public isHydrated: boolean = false
  @observable.shallow public persistedRecords: Array<Recipe> = []

  @action
  public lazyLoadEditVM(recipeGuid: string, name: string = '', attempts: number = 0) {
    if (attempts === 10) return
    if (recipeGuid === 'new') {
      if (this.editVM) this.editVM.dispose()
      this.editVM = new RecipeEditVM(this.rootStore, Recipe.create(this.rootStore.boardsStore.currentBoardId), true)
      if (name !== '') this.editVM.setName(name)
    } else if (recipeGuid === 'empty') {
      if (this.editVM) this.editVM.dispose()
      this.editVM = null
    } else {
      const foundRecipe = this.get(recipeGuid)
      if (!foundRecipe) setTimeout(() => this.lazyLoadEditVM(recipeGuid, name, attempts++), 500)
      else {
        if (this.editVM) this.editVM.dispose()
        this.editVM = new RecipeEditVM(this.rootStore, foundRecipe.clone())
      }
    }
  }

  @action
  public loadEditVM(recipeGuid: string) {
    const foundRecipe = this.get(recipeGuid)
    if (this.editVM) this.editVM.dispose()
    this.editVM = new RecipeEditVM(this.rootStore, foundRecipe.clone())
    return this.editVM
  }

  @computed
  public get currentBoardRecords() {
    return this.persistedRecords.filter((e) => e.BoardId === this.rootStore.boardsStore.currentBoardId)
  }

  protected getWorker() {
    return (DataStoreServiceWorker as any)() as typeof DataStoreServiceWorker
  }
}
