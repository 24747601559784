import { RootStore } from '../../stores/RootStore'
import { Board } from '../aggregate/Board'
import { computed, makeObservable } from 'mobx'

export class BoardRowVM {
  private rootStore: RootStore
  private board: Board

  constructor(rootStore: RootStore, board: Board) {
    this.rootStore = rootStore
    this.board = board
    makeObservable(this)
  }

  @computed
  public get name(): string {
    return this.board.Name
  }

  public get boardId(): number {
    return this.board.BoardId
  }

  @computed
  public get isCurrentBoard() {
    return this.rootStore.boardsStore.currentBoard?.BoardGuid === this.board.BoardGuid
  }
}
