import React from 'react'
import { inject, observer, useLocalStore } from 'mobx-react'
import { IonButton, IonCard, IonCardContent, IonCardHeader, IonCardTitle } from '@ionic/react'
import { AppUpdateAvailableCardVM } from '../view-models/AppUpdateAvailableCardVM'
import { RootStore } from '../../stores/RootStore'
import { EvilIcons } from 'react-web-vector-icons'
import { AppStore } from '../../stores/AppStore'

interface Props {
  appStore?: AppStore
}

const AppUpdateAvailableCard: React.FC<Props> = ({ appStore }) => {
  // const vm = useLocalStore(() => new AppUpdateAvailableCardVM(rootStore))
  // if (!vm.hasUpdateAvailable) return null
  if (!appStore.isSeeding) return null

  return (
    <IonCard id='SeedingCard'>
      <IonCardHeader color='danger'>
        <IonCardTitle>Making it Yours!</IonCardTitle>
      </IonCardHeader>

      <IonCardContent>
        Your new board is currently being populated with the most common items and categories.
        <br />
        <br />
        Please stand by...
      </IonCardContent>
    </IonCard>
  )
}

export default inject('appStore')(observer(AppUpdateAvailableCard))
