import { RootStore } from '../../stores/RootStore'
import { IStorageFileDTO } from '../dtos/IStorageFileDTO'
import { StorageFilesReadService } from './StorageFilesReadService'

export class StorageFileUploadService {
  constructor(private rootStore: RootStore) {}

  public async uploadOfflineStorageFile(storageFile: IStorageFileDTO): Promise<IStorageFileDTO> {
    const dataUrl = await new StorageFilesReadService().getDataUrl(storageFile)
    if (!dataUrl) {
      console.error('No base64 string provided: ' + storageFile.FileName + ' ' + storageFile.UniqueFileName)
      return
    }
    const dto = await this.uploadDataUrl(dataUrl, storageFile)
    return dto
  }

  public async uploadDataUrl(dataUrl: string, storageFile: IStorageFileDTO): Promise<IStorageFileDTO> {
    const blob = this.dataUrlToBlob(dataUrl)
    const dto = await this.uploadBlob(blob, storageFile)
    return dto
  }

  public async uploadBase64(base64: string, storageFile: IStorageFileDTO): Promise<IStorageFileDTO> {
    const blob = this.base64ToBlob(base64, storageFile.MimeType)
    const dto = await this.uploadBlob(blob, storageFile)
    return dto
  }

  public async uploadBlob(blob: Blob, storageFile: IStorageFileDTO): Promise<IStorageFileDTO> {
    const formData = new FormData()
    formData.append('file', blob, storageFile.FileName)
    formData.append('storageFile', JSON.stringify(storageFile))
    const url = process.env.REACT_APP_API_URL + '/api/storagefiles/uploadfile'
    const response = await fetch(url, {
      method: 'POST',
      body: formData,
      headers: {
        Authorization: `Bearer ${this.rootStore.appStore.token}`,
      },
    })
    const dto = await response.json()
    return dto
  }

  private dataUrlToBlob(dataUrl: string) {
    const contentType = dataUrl.split(';')[0].split(':')[1]
    const base64 = dataUrl.split(',')[1]
    return this.base64ToBlob(base64, contentType)
  }

  private base64ToBlob(base64: string, contentType: string) {
    const sliceSize = 512
    const byteCharacters = atob(base64)
    const byteArrays: Uint8Array[] = []
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize)
      const byteNumbers = new Array(slice.length)
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i)
      }
      const byteArray = new Uint8Array(byteNumbers)
      byteArrays.push(byteArray)
    }
    return new Blob(byteArrays, { type: contentType })
  }
}
