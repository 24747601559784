import { observable, action, computed, reaction, makeObservable, runInAction } from 'mobx'
import { RootStore } from 'src/stores/RootStore'
import { StorageFile } from '../aggregate/StorageFile'
import { IStorageFileDTO } from '../dtos/IStorageFileDTO'
import { DataStore } from '@elexient/elexiapp.bits.shared'
import AgentV2 from '../../AgentV2'
import { makePersistable } from 'mobx-persist-store'
import { deserialize } from 'serializr'
import * as DataStoreServiceWorker from '../../shared/DataStoreService.worker'
import { StorageFilesDownloadService } from '../services/StorageFilesDownloadService'

export class StorageFilesStore extends DataStore<RootStore, StorageFile, IStorageFileDTO> {
  constructor(rootStore: RootStore) {
    super(rootStore, StorageFile, AgentV2, 'StorageFiles', 'StorageFile')
    super.setRecords(this.persistedRecords)
    makeObservable(this)
    makePersistable(this, { name: 'StorageFilesStore', properties: ['persistedRecords'] }).then(
      action((st) => this.onHydrationCompleted(st?.isHydrated))
    )
  }

  @observable public isLoaded: boolean = false

  @action
  public async onLoadDataCompleted() {
    const downloadSvc = new StorageFilesDownloadService(this.rootStore)
    await downloadSvc.sync()
    this.markAsLoaded()
  }

  public async onAfterUpdate(item: StorageFile) {
    console.log('downloaded', item)
    if (!item.AvailableOffline) return
    const svc = new StorageFilesDownloadService(this.rootStore)
    await svc.download(item)
    item.markAsDownloaded()
  }

  @action
  private markAsLoaded() {
    this.isLoaded = true
  }

  @action
  public onHydrationCompleted(isHydated?: boolean) {
    this.persistedRecords = observable.array(
      this.persistedRecords.map((e) => makeObservable(deserialize(StorageFile, e)))
    )
    this.setRecords(this.persistedRecords)
    this.isHydrated = Boolean(isHydated)
    if (process.env.NODE_ENV === 'test') this.isHydrated = true
  }

  @observable.shallow public persistedRecords: Array<StorageFile> = []
  @observable public isHydrated: boolean = false

  @computed
  public get currentBoardRecords() {
    return this.persistedRecords.filter((e) => e.BoardId === this.rootStore.boardsStore.currentBoardId)
  }

  protected getWorker() {
    return (DataStoreServiceWorker as any)() as typeof DataStoreServiceWorker
  }
}
