import { Directory, Filesystem } from '@capacitor/filesystem'
import { StorageFile } from '../aggregate/StorageFile'
import { RootStore } from '../../stores/RootStore'

export class StorageFilesWriteService {
  private readonly ITEM_IMAGES_PATH = 'item-images'

  constructor(private rootStore: RootStore) {}

  public async saveBase64String(
    base64String: string,
    extension: string,
    availableOffline: boolean = false
  ): Promise<StorageFile> {
    const hasFSPermission = await Filesystem.checkPermissions()
    if (!hasFSPermission.publicStorage) {
      const permission = await Filesystem.requestPermissions()
      if (!permission.publicStorage) {
        return
      }
    }
    // const response = await fetch(tempLocalUrl)
    const fileName = new Date().getTime() + '.' + extension
    // const blob = await response.blob()
    const storageFile = StorageFile.create(this.rootStore.boardsStore.currentBoardId)
    storageFile.setAvailableOffline(availableOffline)
    storageFile.setFileName(fileName)
    if (availableOffline) await this.saveBase64StringOffline(base64String, storageFile.UniqueFileName)
    return storageFile
  }

  public async saveBlobOffline(blob: Blob, uniqueFileName: string): Promise<void> {
    const base64String = await this.convertBlobToBase64(blob)
    await this.saveBase64StringOffline(base64String, uniqueFileName)
  }

  private async saveBase64StringOffline(base64String: string, uniqueFileName: string): Promise<void> {
    console.log('saveBase64StringOffline', uniqueFileName)
    try {
      await Filesystem.writeFile({
        path: this.ITEM_IMAGES_PATH + '/' + uniqueFileName,
        data: base64String,
        directory: Directory.Data,
      })
      console.log('File written')
    } catch (e) {
      console.error(e)
    }
  }

  private convertBlobToBase64(blob: Blob): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.onerror = reject
      reader.onload = () => {
        resolve(reader.result as string)
      }
      reader.readAsDataURL(blob)
    })
  }
}
