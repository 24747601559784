import React, { useEffect } from 'react'
import { inject, observer, useLocalObservable } from 'mobx-react'
import { AppStore } from '../../../stores/AppStore'
import { IonList, IonCard, IonSpinner, IonContent, IonFab, IonFabButton, IonIcon } from '@ionic/react'
import BotSessionInteractionCard from './BotSessionInteractionCard'
import { BotSessionEditVM } from '../../view-models/edit/BotSessionEditVM'
import { camera, mic } from 'ionicons/icons'
import { RootStore } from '../../../stores/RootStore'
import SpinnerV2 from '../../../shared/spinner-v2/SpinnerV2'

interface Props {
  rootStore?: RootStore
  appStore?: AppStore
  botSessionGuid?: string
  title?: string
  description?: string
  parentRecordTable?: string
  parentRecordGuid?: string
  botName?: string
  inModal?: boolean
}

const Content: React.FC<Props> = (props) => {
  const { appStore } = props
  const vm = useLocalObservable(() => new BotSessionEditVM(props))

  useEffect(() => {
    setTimeout(() => vm.scrollToBottom(), 100)
  }, [vm.interactionCards.length, vm.botSession])

  if (appStore.bodyHeight === 0) return null

  const renderSpinner = () => {
    return <SpinnerV2 type='spinner' isVisible={vm.isProcessing && !vm.isCapturingAudio} />
  }

  const renderListeningIndicator = () => {
    return <SpinnerV2 type='listening' isVisible={vm.isCapturingAudio} />
  }

  const renderMicButton = () => {
    if (!vm.canCaptureAudio) return null
    return (
      <IonFabButton
        color='tertiary'
        onMouseDown={() => vm.startAudioCapture()}
        onMouseUp={() => vm.stopAudioCapture()}
        onTouchStart={() => vm.startAudioCapture()}
        onTouchEnd={() => vm.stopAudioCapture()}
        style={{
          marginTop: '-80px',
        }}
      >
        <IonIcon icon={mic} />
      </IonFabButton>
    )
  }

  const renderUploadButton = () => {
    if (!vm.canUploadPhoto) return null
    return (
      <IonFabButton
        color='tertiary'
        onClick={() => vm.takePhoto()}
        ref={(e) => vm.setUploaderButton(e)}
        style={{
          marginTop: '-60px',
          marginLeft: '-60px',
        }}
      >
        <IonIcon icon={camera} />
      </IonFabButton>
    )
  }

  return (
    <>
      <IonContent
        id='BotSessionEditPage_Content'
        ref={(e) => {
          vm.setContentDiv(e)
          appStore.applyScrollStyles(e)
        }}
        style={{ height: vm.listHeightPx }}
        scrollY
      >
        <div id='DetailsForm'>
          {renderSpinner()}
          {renderListeningIndicator()}
          <IonList>
            {vm.interactionCards.map((card) => (
              <BotSessionInteractionCard key={card.interactionGuid} vm={card} />
            ))}
          </IonList>
          {/* <div style={{ height: vm.spacerHeight }} /> */}
        </div>
      </IonContent>
      <IonFab vertical='bottom' horizontal='center'>
        {renderMicButton()}
        {/* {renderUploadButton()} */}
      </IonFab>
    </>
  )
}

export default inject('appStore', 'rootStore')(observer(Content))
