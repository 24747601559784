export enum ActionTypeEnum {
  ITEM_SAVE = 1,
  LISTITEM_SAVE = 2,
  BOARD_SAVE = 3,
  CATEGORY_SAVE = 4,
  RECIPECATEGORY_SAVE = 5,
  MEALCATEGORY_SAVE = 6,
  RECIPE_SAVE = 7,
  MEAL_SAVE = 8,
  BOARDINVITATION_SAVE = 9,
  STORAGEFILE_UPLOAD = 10,
}